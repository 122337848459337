import React, { useState, useEffect } from 'react';
import { BaseTextField } from 'shared-components/components/FormFields';

interface ROTwoValueTextFieldProps {
  id?: string;
  label?: string | null;
  required?: boolean;
  block?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  value?: string[];
  onChange?: any;
  onBlur?: any;
}

export const parseListOfFloats = (strings: string[]): number[] | null => {
  if (!strings || !Array.isArray(strings) || strings.length === 0) {
    return null;
  }
  return strings.map((string: string) => parseFloat(string));
};

const ROTwoValueTextField = (props: ROTwoValueTextFieldProps): JSX.Element => {
  const [state, setState] = useState('');
  useEffect((): void => {
    if (props.value) {
      if (Array.isArray(props.value)) {
        setState(props.value.join('/'));
      } else {
        setState(props.value);
      }
    }
  }, [props.value]);
  const onBlur = (): void => {
    if (props.onBlur) {
      props.onBlur();
    }
  };
  const { placeholder, required, readOnly, id } = props;
  return (
    <BaseTextField
      id={id ? id : undefined}
      type={'text'}
      placeholder={placeholder || ''}
      value={state || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const pattern = /^$|^(\d+(\.\d{0,3})?)((\/)(\d+(\.\d{0,3})?)?)?$/g;
        if (pattern.test(value)) {
          setState(value);
        }
      }}
      onBlur={(): void => {
        if (props.onChange) {
          let numbers = state.split('/');
          numbers = numbers.filter((number: any): boolean => number !== '');
          props.onChange(numbers);
        }
        if (onBlur) onBlur();
      }}
      required={required || false}
      disabled={readOnly || false}
    />
  );
};

export default ROTwoValueTextField;
