import { useEffect, useContext, useState } from 'react';
import Dashboards from '@highcharts/dashboards';
import DashboardHeader from './DashboardHeader';
import { DashboardContext } from '../DashboardContext';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Dashboard = (props: {
  title: string;
  metric: string;
  metricFunction: (data: any[]) => number;
  config: any;
  metricSuffix?: string;
  metricRound?: number;
  dataType?: string;
  appointmentType?: string;
  dataSource?: string;
}): JSX.Element => {
  const {
    filteredData,
    previousPeriodData,
    subChartFilteredData,
    dateFilterOverride,
    selectedPointIndex,
    dateRange,
    endDate,
    handleDateClick,
    onChartClick,
  } = useContext(DashboardContext);
  const {
    title,
    config,
    metric,
    metricSuffix = '',
    metricRound = 0,
    dataType = 'CarepathCareplanType',
    appointmentType,
    dataSource = 'Lumonus AI',
    metricFunction,
  } = props;

  const [previousPeriodMetric, setPreviousPeriodMetric] = useState<number>(0);
  const [metricChangeIndicator, setMetricChangeIndicator] = useState<string>('');
  const [seeMoreBottomLeft, setSeeMoreBottomLeft] = useState<boolean>(false);
  const [seeMoreBottomRight, setSeeMoreBottomRight] = useState<boolean>(false);
  const [seeMoreUpdated, setSeeMoreUpdated] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const theme = useTheme();

  useEffect(() => {
    const filterAppointmentType = dataType === 'AppointmentWithPractitionersType' && appointmentType;

    const data = filterAppointmentType
      ? filteredData.filter(
          (d: any) =>
            d.__typename === dataType &&
            (d.appointment.appointmentType?.toLowerCase().includes(appointmentType) ||
              d.appointment.description?.toLowerCase().includes(appointmentType)),
        )
      : filteredData.filter((d: any) => d.__typename === dataType);

    const subChartData = filterAppointmentType
      ? subChartFilteredData.filter(
          (d: any) =>
            d.__typename === dataType &&
            (d.appointment.appointmentType.toLowerCase().includes(appointmentType) ||
              d.appointment.description?.toLowerCase().includes(appointmentType)),
        )
      : subChartFilteredData.filter((d: any) => d.__typename === dataType);

    const previousPeriodDataFiltered = filterAppointmentType
      ? previousPeriodData.filter(
          (d: any) =>
            d.__typename === dataType &&
            (d.appointment.appointmentType.toLowerCase().includes(appointmentType) ||
              d.appointment.description?.toLowerCase().includes(appointmentType)),
        )
      : previousPeriodData.filter((d: any) => d.__typename === dataType);

    setData(data);

    Dashboards.board(
      'dashboard-container',
      config({
        theme: theme, // passing in theme because the hook can't be called inside the config function
        data: data,
        subChartData: subChartData,
        filterOverride: dateFilterOverride,
        selectedIndex: selectedPointIndex,
        seeMoreUpdated: seeMoreUpdated,
        seeMoreBottomLeft: seeMoreBottomLeft,
        seeMoreBottomRight: seeMoreBottomRight,
        dateRange: dateRange,
        endDate: endDate,
        toggleSeeMore: toggleSeeMore,
        onPointClick: handleDateClick,
      }),
    );

    const current = metricFunction(data);
    const previous = metricFunction(previousPeriodDataFiltered);
    const percentChange = (Math.abs(current - previous) / previous) * 100;
    setPreviousPeriodMetric(percentChange);
    setMetricChangeIndicator(current > previous ? 'more' : 'less');

    setSeeMoreUpdated(false);
  }, [subChartFilteredData, seeMoreBottomLeft, seeMoreBottomRight]);

  const toggleSeeMore = (chart: string) => {
    setSeeMoreUpdated(true);

    if (chart === 'sub-chart-left') {
      setSeeMoreBottomLeft(!seeMoreBottomLeft);
    } else {
      setSeeMoreBottomRight(!seeMoreBottomRight);
    }

    onChartClick();
  };

  return (
    <Stack height="100%">
      <DashboardHeader title={title} dataSource={dataSource} />
      <Stack
        justifyContent="center"
        alignItems="center"
        fontSize="24px"
        borderRadius="8px"
        marginTop="20px"
        p="20px 0"
        sx={{ backgroundColor: 'background.secondary' }}>
        <Typography variant="h5">
          {metricFunction(data).toFixed(metricRound)}
          {metricSuffix} {metric}
        </Typography>
        <Typography color={theme.palette.text.secondary} height={20}>
          {dateRange !== 'All time' && !isNaN(previousPeriodMetric) && isFinite(previousPeriodMetric) && (
            <Stack alignItems="center" direction="row">
              <ArrowForward
                style={{
                  zIndex: 1,
                  marginRight: '5px',
                  transform: `rotate(${metricChangeIndicator === 'more' ? '-' : ''}90deg)`,
                  width: '16px',
                }}
              />
              {previousPeriodMetric.toFixed(metricRound)}% {metricChangeIndicator} than previous{' '}
              {dateRange.split('Past ')[1]}
            </Stack>
          )}
        </Typography>
      </Stack>
      <Stack id="dashboard-container" flex={1} className="highcharts-light" />
    </Stack>
  );
};

export default Dashboard;
