import { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Highcharts from 'highcharts';
import Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/datagrid';
import LayoutModule from '@highcharts/dashboards/modules/layout';
import { StyledTile } from '../Dashboard';
import './InsightsDashboard.scss';
import Dashboard from './Components/Dashboard';
import { DashboardContextProvider } from './DashboardContext';
import MockData from './Utils/MockData';
import MockAppointmentData from './Utils/MockAppointmentData';
import { GET_CAREPLANS_QUERY, DOCTOR_APPOINTMENT_QUERY, INSIGHTS_USER_QUERY } from './queries';
import DashboardOverview from './Components/DashboardOverview';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import { DataFunctions } from './Utils/dataFunctions';
import * as Config from './Components/DashboardConfigs';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import NavBar from './Components/NavBar';
import { convertDateFormat, APPOINTMENT_TYPES } from './Utils/utils';
import { isDemo } from 'op-utils';

// Highcharts setup
LayoutModule(Dashboards);
exporting(Highcharts);
exportData(Highcharts);
Dashboards.HighchartsPlugin.custom.connectHighcharts(Highcharts);
Dashboards.DataGridPlugin.custom.connectDataGrid(DataGrid);
Dashboards.PluginHandler.addPlugin(Dashboards.HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(Dashboards.DataGridPlugin);
Highcharts.setOptions({
  lang: {
    contextButtonTitle: 'Download data',
  },
});

type SectionNames = 'Quality' | 'Access' | 'Efficiency' | 'Activity';

// DATA PROCESSING FUNCTIONS
const processMockData = (mockData: any[], mockAppointmentData: any[]) => {
  const processedCareplanData = mockData.map((record) => ({
    ...record,
    submittedAt: convertDateFormat(record.submittedAt),
    description: '',
    __typename: 'CarepathCareplanType',
  }));

  const processedAppointmentsData = mockAppointmentData.map((record) => ({
    appointment: {
      patient: { id: record.patientId },
      location: { name: record.location },
      submittedAt: record.submittedAt,
      appointmentType: record.appointmentType,
      description: record.description,
    },
    submittedAt: record.submittedAt,
    createdBy: record.physician,
    tumourStream: 'Unspecified',
    treatingDepartment: record.treatingDepartment,
    relatedPractitioners: [
      {
        practitioner: {
          firstName: record.physician,
          lastName: record.physician,
        },
        relationshipType: 'Oncologist',
      },
    ],
    __typename: 'AppointmentWithPractitionersType',
  }));

  return [...processedCareplanData, ...processedAppointmentsData];
};

const processCareplans = (careplanData: any[]) => {
  if (!careplanData) {
    return [];
  }

  const getSiteValue = (siteValues: any[], name: string, defaultValue: any = '') => {
    return (
      siteValues
        .find((siteValue: { field: { name: string } }) => siteValue.field.name === name)
        ?.value.replace(/"/g, '') || defaultValue
    );
  };

  const normalizeTumourStream = (tumourStream: string): string => {
    switch (tumourStream) {
      case 'Miscellaneous':
        return 'Misc';
      case 'GI, Colo-rectal + Abdo':
        return 'GI';
      case 'Sarcoma - Soft Tissue':
        return 'Sarcoma';
      default:
        return tumourStream;
    }
  };

  // grouping similar techniques to reduce the number of categories:
  //    - '3D Conformal ARC', '3D', 'Electrons', 'Simple', 'Clinical', 'Conformal RT' = 'Conformal'
  //    - 'Brachy - HDR', 'Brachy - LDR' and 'Brachy Seeds' = 'Brachy'
  //    - 'SBRT VMAT', 'SBRT IMRT', 'SRS IMRT', 'SRS VMAT' = 'Stereotactic'
  //    - 'VMAT', 'IMRT', 'VMAT/IMRT', 'Tangential IMRT' = 'IMRT/VMAT'
  const normalizeTechnique = (techniqueValue: string): string => {
    const conformalTechniques = ['3D', '3D Conformal ARC', 'Electrons', 'Simple', 'Clinical', 'Conformal RT'];
    const steroTechniques = ['SBRT VMAT', 'SBRT IMRT', 'SRS IMRT', 'SRS VMAT'];
    const imrtVmatTechniques = ['VMAT', 'IMRT', 'VMAT/IMRT', 'Tangential IMRT'];

    if (techniqueValue.includes('Brachy')) return 'Brachy';
    if (conformalTechniques.includes(techniqueValue)) return 'Conformal';
    if (steroTechniques.includes(techniqueValue)) return 'Stereotactic';
    if (imrtVmatTechniques.includes(techniqueValue)) return 'IMRT/VMAT';
    return techniqueValue;
  };

  const excludedDoctors = ['Nick Stock', 'Gary Bee', 'LumonusAI Service', 'demo_ro@lumonus.com', 'AI Lumonus'];

  return (careplanData as any)['careplans']
    .filter((record: any) => !excludedDoctors.includes(record.createdBy))
    .map((record: any) => {
      const siteValues = record.sitegroupSet?.[0]?.siteSet?.[0]?.sitevaluesSet || [];
      const techniqueValue = getSiteValue(siteValues, 'Technique', '');
      const fractionsValue = parseFloat(getSiteValue(siteValues, 'Fractions', 0));

      // if there are multiple dose values, take the first one for calculating fraction dose
      const doseValue = parseFloat(
        getSiteValue(siteValues, 'Dose', '0').replace('[', '').replace(']', '').split(',')[0],
      );

      return {
        ...record,
        createdBy: record.createdBy || 'Unspecified',
        treatingDepartment: record.treatingDepartment || CurrentAppConfig?.Insights?.defaultDepartment,
        diagnosisCode: `${record.diagnosisCode} ${record.diagnosis?.diagnosisCode?.diagnosisDescription || ''}`,
        tumourStream: normalizeTumourStream(record.diagnosis?.diagnosisCode?.tumourStream?.name || ''),
        technique: normalizeTechnique(techniqueValue),
        fractions: fractionsValue,
        fractionDose: doseValue / fractionsValue,
        adherence: true,
        linac: '',
        sim_to_treat: 0,
        referrer: '',
      };
    });
};

const processAppointments = (appointmentData: any[], processedCareplanData: any[]) => {
  if (!appointmentData) {
    return [];
  }

  return (appointmentData as any)['doctorAppointmentsByDateWithPractitioners'].map((record: any) => {
    // assuming that we can match appointments to careplans by joining on patient id
    // this may work for simple sites, but likely need a more robust way to match these moving forward
    const careplan = processedCareplanData
      ? processedCareplanData.find((careplan) => careplan.patient?.id === record.appointment.patient?.id)
      : null;

    // assume first related practioner with relationshipType = 'oncologist' is the one assigned to the appointment
    const relatedOncologist = record.relatedPractitioners.find(
      (practitioner: any) => practitioner.relationshipType === 'oncologist',
    );

    const physicianFullName = relatedOncologist
      ? `${relatedOncologist.practitioner.firstName} ${relatedOncologist.practitioner.lastName}`
      : 'Unspecified';

    return {
      ...record,
      submittedAt: record.appointment.startTime,
      createdBy: physicianFullName,

      // if a matching careplan is not found, return the appointment with default values
      // TODO: needs to be dynamic once there is more than 1 site in a tenant
      treatingDepartment: CurrentAppConfig?.Insights?.defaultDepartment,
      tumourStream: careplan?.tumourStream || 'Unspecified',
    };
  });
};

// TODO: change this to use start date from the date range picker
const appointmentsStartDate = new Date(2024, 0, 1);
const appointmentsEndDate = new Date();

const InsightsDashboard = (): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const { data: userData } = useQuery(INSIGHTS_USER_QUERY);

  const [dashboardData, setDashboardData] = useState<any>([]);
  const [dataRefreshed, setDataRefreshed] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState('index');

  const [openSections, setOpenSections] = useState<Record<SectionNames, boolean>>({
    Quality: true,
    Access: true,
    Efficiency: true,
    Activity: true,
  });

  const navToPage = (page: string) => {
    if (currentPage === page) {
      return;
    }

    if (page === 'index') {
      history.push('/radiation/insights');
    } else {
      history.push(`/radiation/insights/${page}`);
    }
    setCurrentPage(page);
  };

  const toggleSection = (section: SectionNames) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };

  // set current page
  useEffect(() => {
    const getPageName = (pathname: string) => {
      const parts = pathname.replace('/radiation/insights', '').split('/').filter(Boolean);
      return parts[0] || 'index';
    };

    setCurrentPage(getPageName(location.pathname));
    restoreScrollPosition(0);
    scrollPositionRef.current = 0;

    const unlisten = history.listen((location) => {
      setCurrentPage(getPageName(location.pathname));
    });

    return () => {
      unlisten();
    };
  }, [history, location.pathname]);

  // bunch of workarounds to save and restore scroll position when switching between pages
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollPositionRef = useRef<number>(0);

  const saveScrollPosition = useCallback(() => {
    if (tableWrapperRef.current && tableWrapperRef.current.scrollTop !== 0) {
      scrollPositionRef.current = tableWrapperRef.current.scrollTop;
    }
  }, []);

  const restoreScrollPosition = (override?: number) => {
    if (tableWrapperRef.current) {
      requestAnimationFrame(() => {
        if (override || override === 0) {
          tableWrapperRef.current!.scrollTop = override;
        } else {
          tableWrapperRef.current!.scrollTop = scrollPositionRef.current;
        }
      });
    }
  };

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current;
    if (tableWrapper) {
      tableWrapper.addEventListener('scroll', saveScrollPosition);
    }

    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener('scroll', saveScrollPosition);
      }
    };
  }, [saveScrollPosition]);

  // fetch careplan and appointment data
  const { data: careplanData, loading: careplansLoading } = useQuery(GET_CAREPLANS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      rowsPerPage: 9999,
      status: ['Plan Aim', 'Prescription', 'Prescription Directive'],
      onlyForRequestingPractitioner: false,
      insightsRequest: true,
    },
  });

  const { data: appointmentData, loading: appointmentsLoading } = useQuery(DOCTOR_APPOINTMENT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      startDate: appointmentsStartDate,
      endDate: appointmentsEndDate,
      appointmentTypes: APPOINTMENT_TYPES,
      excludeCancelled: true,
      allPractitioners: true,
    },
    skip: careplansLoading || !careplanData,
  });

  // process data when careplan and appointment data is available
  useEffect(() => {
    if (CurrentAppConfig.Insights?.mockData) {
      const updatedData = processMockData(MockData, MockAppointmentData);
      setDashboardData(updatedData);
      return;
    }

    const processedCareplansData = processCareplans(careplanData);
    const processedAppointmentsData = processAppointments(appointmentData, processedCareplansData);

    setDashboardData([...processedCareplansData, ...processedAppointmentsData]);
    setDataRefreshed(true);
  }, [careplanData, appointmentData]);

  useEffect(() => {
    if (userData && !userData.user.isInsightsUser && !isDemo) {
      history.replace('/error');
    }
  }, [userData, history]);

  return (
    <DashboardContextProvider
      data={dashboardData}
      dataRefreshed={dataRefreshed}
      setDataRefreshed={setDataRefreshed}
      navToPage={navToPage}
      onChartClick={restoreScrollPosition}
      isLoading={careplansLoading}>
      <Stack direction="row" width="100%" height="100%">
        {currentPage !== 'index' && (
          <NavBar
            openSections={openSections}
            toggleSection={toggleSection}
            navToPage={navToPage}
            currentPage={currentPage}
            theme={theme}
          />
        )}
        <StyledTile sx={{ paddingTop: '20px', paddingBottom: '10px', margin: 0, boxShadow: 'none' }}>
          <Stack
            justifyContent="space-between"
            width="100%"
            height="80%"
            flexGrow={1}
            ref={tableWrapperRef}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              marginRight: '-15px',
              paddingRight: '15px',
              '&::-webkit-scrollbar': {
                width: '8px',
                WebkitAppearance: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#cfcfcf',
                borderRadius: '8px',
                boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
              },
            }}>
            {currentPage === 'index' && (
              <DashboardOverview
                careplansLoading={careplansLoading || !careplanData}
                appointmentsLoading={appointmentsLoading || !appointmentData}
              />
            )}
            {currentPage === 'carepathway' && (
              <Dashboard
                title="Carepathway Utilization"
                metric="Careplan Adherence"
                metricFunction={DataFunctions.calcAdherencePercentage}
                config={Config.CarepathUtilisation}
                metricSuffix="%"
              />
            )}
            {currentPage === 'techniques' && (
              <Dashboard
                title="Technique Utilization"
                metric="IMRT/VMAT/Stereotactic plans"
                metricFunction={DataFunctions.calcComplexTechnique}
                config={Config.TechniqueUtilisation}
                metricSuffix="%"
              />
            )}
            {currentPage === 'fractions' && (
              <Dashboard
                title="Fractionation Schedule"
                metric="Average Fractions"
                metricFunction={DataFunctions.calcAverageFractions}
                metricRound={1}
                config={Config.FractionationSchedule}
              />
            )}
            {currentPage === 'referrers' && (
              <Dashboard
                title="Top 10 Referrers"
                metric="Total Referrals"
                metricFunction={DataFunctions.calcTotalReferrals}
                config={Config.Top10Referrers}
              />
            )}
            {currentPage === 'diagnosis' && (
              <Dashboard
                title="Diagnosis"
                metric="Total Diagnoses"
                metricFunction={DataFunctions.calcTotalDiagnosis}
                config={Config.Diagnosis}
              />
            )}
            {currentPage === 'activity' && (
              <Dashboard
                title="Clinical Activity"
                metric="Total Plans"
                metricFunction={DataFunctions.calcTotalPlans}
                config={Config.ClinicalActivity}
              />
            )}
            {currentPage === 'machines' && (
              <Dashboard
                title="Machine Utilization"
                metric="Average Daily Treatments Per Linac"
                metricFunction={DataFunctions.calcAverageDailyTreatmentsPerLinac}
                config={Config.MachineUtilisation}
                metricRound={1}
                dataType="AppointmentWithPractitionersType"
                appointmentType="treat"
                dataSource={CurrentAppConfig.Insights?.dataSource}
              />
            )}
            {currentPage === 'wait-times' && (
              <Dashboard
                title="Patient Wait Times"
                metric="Average Sim to Treatment Time (Days)"
                metricFunction={DataFunctions.calcAverageSimToTreat}
                config={Config.PatientWaitTimes}
                metricRound={1}
                dataType="AppointmentWithPractitionersType"
                dataSource={CurrentAppConfig.Insights?.dataSource}
              />
            )}
            {currentPage === 'productivity' && (
              <Dashboard
                title="Planning Productivity"
                metric="Average Daily Plans"
                metricFunction={DataFunctions.calcAverageDailyPlans}
                config={Config.PlanningProductivity}
                metricRound={1}
              />
            )}
            {currentPage === 'simulations' && (
              <Dashboard
                title="Total Simulations"
                metric="Total Simulations"
                metricFunction={DataFunctions.calcTotalSimAppointments}
                config={Config.TotalSimulations}
                dataType="AppointmentWithPractitionersType"
                appointmentType="sim"
                dataSource={CurrentAppConfig.Insights?.dataSource}
              />
            )}
            {currentPage === 'treatments' && (
              <Dashboard
                title="Total Treatments (Fractions)"
                metric="Total Treatments (Fractions)"
                metricFunction={DataFunctions.calcTotalTreatmentAppointments}
                config={Config.TotalTreatments}
                dataType="AppointmentWithPractitionersType"
                appointmentType="treat"
                dataSource={CurrentAppConfig.Insights?.dataSource}
              />
            )}
          </Stack>
        </StyledTile>
      </Stack>
    </DashboardContextProvider>
  );
};

export default InsightsDashboard;
