import { useContext, useState, useEffect } from 'react';
import { DashboardContext } from '../DashboardContext';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Typography, Stack, Button, Box, Modal, Tooltip, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InsightsDateAggregation } from 'op-enums';
import BlurredChart from '../Assets/BlurredChart.png';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OverviewChart from './Charts/OverviewCharts/OverviewChart';
import OverviewDonutChart from './Charts/OverviewCharts/OverviewChartDonut';
import { getDeterministicOffset } from '../Utils/utils';
import { LoadingSpinner } from 'shared-components/components';

const OverviewCard = (props: {
  title: string;
  type?: string;
  page: string;
  metricSuffix?: string;
  metricRound?: number;
  targetType?: string;
  enabled?: boolean;
  description?: string;
  modalImage?: any;
  dataType?: string;
  appointmentType?: string;
  loading: boolean;
  aggregateFunction: (data: any, aggregateBy: string | InsightsDateAggregation) => (string | number)[][];
  metricFunction: (data: any[]) => number;
}): JSX.Element => {
  const { filteredData, previousPeriodData, dateRange, navToPage } = useContext(DashboardContext);
  const {
    title,
    type,
    page,
    metricSuffix = '',
    metricRound = 0,
    targetType,
    enabled,
    description,
    modalImage,
    dataType = 'CarepathCareplanType',
    appointmentType,
    aggregateFunction,
    metricFunction,
    loading,
  } = props;

  const [data, setData] = useState<any[]>([]);
  const [previousData, setPreviousData] = useState<any[]>([]);
  const [aggregatedData, setAggregatedData] = useState<any[][]>([]);
  const [targetData, setTargetData] = useState<any[]>([]);
  const [metric, setMetric] = useState<string>('');
  const [previousPeriodMetric, setPreviousPeriodMetric] = useState<number>(0);
  const [metricChangeIndicator, setMetricChangeIndicator] = useState<string>('');
  const [isHovered, setIsHovered] = useState(false);
  const [dateAggregation, setDateAggregation] = useState<InsightsDateAggregation>(InsightsDateAggregation.DAYS);
  const [modalOpen, setModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme();

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const filterAppointmentType = dataType === 'AppointmentWithPractitionersType' && appointmentType;

    const data = filterAppointmentType
      ? filteredData.filter(
          (d: any) =>
            d.__typename === dataType &&
            (d.appointment.appointmentType?.toLowerCase().includes(appointmentType) ||
              d.appointment.description?.toLowerCase().includes(appointmentType)),
        )
      : filteredData.filter((d: any) => d.__typename === dataType);

    const previousPeriodDataFiltered = filterAppointmentType
      ? previousPeriodData.filter(
          (d: any) =>
            d.__typename === dataType &&
            (d.appointment.appointmentType.toLowerCase().includes(appointmentType) ||
              d.appointment.description?.toLowerCase().includes(appointmentType)),
        )
      : previousPeriodData.filter((d: any) => d.__typename === dataType);

    setData(data);
    setPreviousData(previousPeriodDataFiltered);
  }, [filteredData]);

  useEffect(() => {
    setAggregatedData(aggregateFunction(data, type === 'donut' ? 'tumourStream' : dateAggregation));
    setMetric(metricFunction(data).toFixed(metricRound) + metricSuffix);

    const current = metricFunction(data);
    const previous = metricFunction(previousData);
    const percentChange = (Math.abs(current - previous) / previous) * 100;
    setPreviousPeriodMetric(percentChange);
    setMetricChangeIndicator(current > previous ? 'more' : 'less');
  }, [data]);

  useEffect(() => {
    // target data does not exist in the mock data at the moment. Making up values for it for now
    const average = aggregatedData.reduce((acc, point) => acc + point[1], 0) / aggregatedData.length;

    if (targetType === 'flat') {
      setTargetData(aggregatedData.map((point: any) => average));
    } else if (targetType === 'track') {
      setTargetData(aggregatedData.map((point: any) => getDeterministicOffset(point[1], average - 5, average + 5)));
    }
  }, [aggregatedData]);

  return (
    <Stack
      id="overview-chart-container"
      p={3}
      sx={{
        width: '400px',
        maxWidth: '400px',
        maxHeight: '272px',
        minHeight: '272px',
        borderColor: theme.palette.divider,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: !!enabled ? 'pointer' : 'default',
      }}
      onClick={() => {
        return enabled && !loading ? navToPage(page) : null;
      }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
        <Stack direction="row">
          <Typography
            style={{
              textDecoration: isHovered ? 'underline' : 'none',
            }}
            onMouseEnter={() => setIsHovered(true && !!enabled)}
            onMouseLeave={() => setIsHovered(false)}>
            {title}
          </Typography>
          <Tooltip
            title={description}
            open={showTooltip}
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  marginLeft: '14px',
                },
              },
            }}>
            <div style={{ width: 20 }}>
              {enabled && (
                <HelpOutlineIcon
                  style={{ marginLeft: '8px', width: '20px' }}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
              )}
            </div>
          </Tooltip>
        </Stack>
        {enabled && dateRange !== 'All time' && !isNaN(previousPeriodMetric) && isFinite(previousPeriodMetric) && (
          <ArrowForward
            style={{
              transform: `rotate(${metricChangeIndicator === 'more' ? '-' : ''}90deg)`,
            }}
          />
        )}
      </Stack>
      {enabled && (
        <Stack>
          {loading ? (
            <LoadingSpinner loadingText={''} />
          ) : (
            <Stack>
              <Typography variant="h5" sx={{ lineHeight: 1, marginBottom: '5px' }}>
                {metric}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
                {dateRange !== 'All time' && !isNaN(previousPeriodMetric) && isFinite(previousPeriodMetric) && (
                  <Stack>
                    {previousPeriodMetric.toFixed(metricRound)}% {metricChangeIndicator} than previous{' '}
                    {dateRange.split('Past ')[1]}
                  </Stack>
                )}
              </Typography>
              {type == 'donut' ? (
                <OverviewDonutChart aggregatedData={aggregatedData.slice(1)} />
              ) : (
                <OverviewChart
                  aggregatedData={aggregatedData}
                  targetData={targetData}
                  yAxisSuffix={metricSuffix}
                  dateAggregation={dateAggregation || ''}
                />
              )}
            </Stack>
          )}
        </Stack>
      )}
      {!enabled && (
        <Stack>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="center"
            position="relative"
            paddingBottom={1}
            sx={{ minHeight: 180, width: '100%' }}>
            <Box component="img" src={BlurredChart} position="absolute" />
            <Button
              onClick={() => setModalOpen(true)}
              sx={{
                position: 'absolute',
                borderRadius: '4px',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}>
              <Typography color={theme.palette.primary.contrastText}>COMING SOON</Typography>
            </Button>
          </Stack>
        </Stack>
      )}
      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '1300px',
            bgcolor: theme.palette.background.paper,
            borderRadius: 1,
            paddingTop: 2,
            paddingBottom: 4.5,
            minHeight: '800px',
          }}>
          <Stack direction="row">
            <Typography variant="h6" paddingLeft={4}>
              {title}
            </Typography>
            <Typography variant="h6" color={theme.palette.text.secondary} paddingLeft={0.5}>
              (Coming soon...)
            </Typography>
          </Stack>
          <Typography variant="body1" paddingLeft={4}>
            {description}
          </Typography>
          <Divider sx={{ margin: '20px 0' }} />
          <Stack justifyContent="center" alignItems="center" marginBottom={5}>
            <Box component="img" src={modalImage} />
            <Typography
              variant="h2"
              color={theme.palette.text.secondary}
              position="absolute"
              top="55%"
              left="50%"
              sx={{ transform: 'translate(-50%, -50%)' }}>
              Example
            </Typography>
          </Stack>
          <Divider sx={{ margin: '10px 0' }} />
          <Typography
            variant="button"
            position="absolute"
            onClick={handleClose}
            sx={{ right: '20px', cursor: 'pointer' }}>
            CLOSE
          </Typography>
        </Box>
      </Modal>
    </Stack>
  );
};

export default OverviewCard;
