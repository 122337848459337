import { gql } from '@apollo/client';

export const HAS_LATEST_NOTIFICATION = gql`
  query HasLatestNotification {
    hasLatestNotification {
      hasLatest
    }
  }
`;

export const UPDATE_USER_NOTIFICATION = gql`
  mutation UpdateUserNotification {
    updateUserNotification {
      ok
    }
  }
`;

export const LOGGED_IN_PATIENT_QUERY = gql`
  {
    patient {
      id
      ida
    }
  }
`;

export const TENANT_CONFIG_QUERY = gql`
  query tenantConfig {
    tenantConfig {
      id
      tenantName
      insightsVisible
    }
  }
`;
