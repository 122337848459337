import React, { Dispatch, SetStateAction } from 'react';
import { DATATYPE } from 'shared-components/components/UIFormComponents/SimpleTable';
import { LabPanelType } from './Interfaces';
import Table from './Table';
import dayjs from 'dayjs';

interface Props {
  data: LabPanelType[];
  setActiveResult: Dispatch<SetStateAction<LabPanelType | undefined>>;
  setActiveDocument: Dispatch<SetStateAction<string | undefined>>;
}

const ResultsTable = ({ data, setActiveResult, setActiveDocument }: Props): JSX.Element => {
  const tableHeaders = [
    { title: 'Type', key: 'description', type: DATATYPE.STRING },
    { title: 'Observation Date', key: 'observationDate', sort: true, type: DATATYPE.DATE },
  ];

  const tableData = data.map((panel: LabPanelType) => {
    return [
      {
        title: panel.panelName !== '' ? panel.panelName : 'No title available',
        subtitle: panel.status,
        id: panel.id,
        key: 'description',
      },
      {
        title: panel.observationDate,
        key: 'observationDate',
      },
    ];
  });

  const getPanelById = (id: string): LabPanelType => {
    return data.filter((panel) => {
      return panel.id === id;
    })[0];
  };

  return (
    <Table
      testId={'labs-results-panel-table'}
      tableHeaders={tableHeaders}
      tableData={tableData}
      dateFormat={'L'}
      onRowClick={(item): void => {
        if (item[0].id) {
          setActiveResult(getPanelById(item[0].id));
          setActiveDocument(undefined);
        }
      }}
      defaultSelectedIndex={0}
    />
  );
};

export default ResultsTable;
