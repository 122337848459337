import React from 'react';
import dayjs from 'dayjs';
import { Badge } from 'shared-components/components';
import { CardTitle, CardBody, CardBodyGroup, BubbleTitle, CardText } from './styles';
import CONSTANTS from './constants';
import { useTheme } from '@mui/material';
const { STATUS, NOTES, ASSIGNED, NO_ALERTS } = CONSTANTS;

const AlertsCard = ({ name, alerts }: { name: string; alerts: any }): JSX.Element => {
  const theme = useTheme();
  return (
    <div data-testid="alertsCard">
      <CardTitle>
        {name}{' '}
        <Badge
          $bgColor={alerts?.length ? theme.palette.error.main : theme.palette.warning.dark}
          data-testid="alertsBadge">
          {alerts?.length || '?'}
        </Badge>
      </CardTitle>
      <CardBody>
        {alerts?.length > 0 ? (
          alerts.map((alert: any, index: number) => (
            <CardBodyGroup key={`${alerts.name}-${alerts.index}`}>
              <BubbleTitle>
                {index + 1}. {alert.name}
              </BubbleTitle>
              <CardText>
                {STATUS}: <b>{alert.status}</b> | {ASSIGNED}:{' '}
                <b>{alert.assigned && dayjs.utc(alert.assigned).format('L')}</b>
              </CardText>
              <CardText>
                {NOTES}: <b>{alert.comment}</b>
              </CardText>
            </CardBodyGroup>
          ))
        ) : (
          <CardBodyGroup>
            <b>{NO_ALERTS}</b>
          </CardBodyGroup>
        )}
      </CardBody>
    </div>
  );
};

export default AlertsCard;
