import { gql, useApolloClient, useQuery } from '@apollo/client';
import { FormStatus, FormType, RoleType } from 'op-enums';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Typography, Button, Stack, Box, TextField, InputAdornment, Chip, Divider } from '@mui/material';

import { RouteComponentProps, useLocation } from 'react-router-dom';
import { History } from 'history';

import 'url-search-params-polyfill';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { GET_TAG_OPTIONS } from 'op-components/PatientCard/PatientCardQueries';
import { RECORD_LOCK_QUERY, RELEASE_LOCK_MUTATION } from 'op-graphql';
import { Patient, PatientOptIn, PatientSearchQueryItem, PatientSearchQueryItemAddress } from 'op-interfaces';
import { Region } from 'shared-components/enums';
import { GraphUpdate } from 'shared-components/interfaces';
import { DateTimeConverter, Logger, navigateToExternalURL } from 'shared-components/utils';
import {
  CREATE_SUBMISSION,
  GET_USER_DETAILS,
  LOGOUT_MUTATION,
  PATIENT_SEARCH,
  SEARCH_FILTERS_REF_DATA_QUERY,
  SEND_PX_EMAIL_INVITATION,
} from './PatientSearchQueries';

import { ModalInfo, ModalOptIn, SearchFilter, SearchMultiSelectField } from 'op-components';
import { LoadingSpinner, StandardDialog } from 'shared-components/components';

import PatientAccessModal from 'op-components/Modals/PatientAccessModal/PatientAccessModal';
import { isUs } from 'op-utils/helpers';
import { ModalContentSubmissionError } from 'shared-components/components';
import { BulletPoint, EMROutlined, ErrorCross, Success } from 'shared-components/images';
import {
  ErrorOutline as ErrorOutlineIcon,
  Search as SearchIcon,
  CheckCircleOutlined as CheckCircleIcon,
} from '@mui/icons-material';
import { COPY } from './constants';
import { LoadingButton } from '@mui/lab';

const { PSO, SUPERUSER, NURSE } = RoleType;

const logger = new Logger('PatientSearch');

const EMPTY_PATIENT_OPT_IN = {
  id: '',
  firstName: '',
  lastName: '',
  pxOptedIn: null,
  primaryPhone: '',
  email: '',
  pxOptOutComment: '',
  emrInstance: '',
};
const EMPTY_PATIENT_ADDRESS = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  postcode: '',
};

const EMPTY_PATIENT = {
  id: '',
  firstName: '',
  lastName: '',
  ida: '',
  dob: '',
  primaryPhone: '',
  primaryPhoneFormatted: '',
  email: '',
  primaryCenterAlias: '',
  address: EMPTY_PATIENT_ADDRESS,
  pxOptedIn: null,
  pxOptOutComment: '',
  regFormStatus: '',
  overallStatus: '',
  userProfile: {
    id: '',
    registrationAccessType: '',
    showRegistration: true,
    showHealthAssessment: true,
    patientFeatures: {
      distressThermometerEotEnabled: false,
      distressThermometerEnabled: false,
      managementPlansEnabled: false,
      covidEnabled: false,
    },
  },
  horizonCenterId: '',
  tags: [],
  emrInstance: '',
};

enum LSKey {
  REGISTRATIONS = 'registrations',
  LOCATIONS = 'locations',
  TAGS = 'tags',
}

const StyledTagStatus = styled('span')`
  border-radius: 100px;
  font-size: 12px;
  padding: 3px 14px;
  white-space: nowrap;
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.palette.tag.main};
  margin-right: 5px;
`;

export interface PatientSearchQueryResultData {
  searchPatients: PatientSearchQueryItem[];
  user?: {
    features: {
      DT: boolean;
    };
  };
}

const ContentDetails = ({
  title,
  value,
  titleAutoWidth,
}: {
  title: string;
  value?: string | JSX.Element | false;
  titleAutoWidth?: boolean;
}): JSX.Element => (
  <Stack direction="row" gap={2} data-testid="patient-search-content-detail">
    <Typography width={titleAutoWidth ? 'auto' : '111px'} minWidth={titleAutoWidth ? 'auto' : '111px'} fontWeight={600}>
      {title}&#58;
    </Typography>
    <Typography width="270px">{value || 'Not provided'}</Typography>
  </Stack>
);

const RegistrationSignUpStatus = ({ patient }: { patient: PatientSearchQueryItem }): JSX.Element => {
  let icon = <ErrorOutlineIcon color="warning" />;
  let registrationStatus = 'Not provided';
  if (patient.userProfile.registrationAccessType !== '') {
    icon = <CheckCircleIcon color="primary" />;
    if (patient.userProfile.registrationAccessType === 'atHome') {
      registrationStatus = 'At home';
    } else if (patient.userProfile.registrationAccessType === 'inClinic') {
      registrationStatus = 'In clinic';
    }
  }
  return (
    <Stack direction="row" marginLeft={-1.5} gap={0.5} alignItems="center">
      {icon}
      <div data-testid="registration-status">{registrationStatus}</div>
    </Stack>
  );
};

const PXSignUp = ({ signedUp }: { signedUp: boolean | null }): JSX.Element => {
  let icon = <ErrorOutlineIcon color="warning" />;
  let message = COPY.NOT_SIGNED_UP;

  if (signedUp === true) {
    icon = <Success />;
    message = COPY.SIGNED_UP;
  } else if (signedUp === false) {
    icon = <ErrorCross />;
    message = COPY.OPTED_OUT;
  }

  return (
    <Stack direction="row" marginLeft={-1.5} gap={0.5} alignItems="center">
      {icon}
      <div data-testid="signup-status">{message}</div>
    </Stack>
  );
};

const NoSearchResults = ({ searchTerm }: { searchTerm?: string }): JSX.Element => {
  const lineText = searchTerm ? `"${searchTerm}"` : 'your selected filters';

  return (
    <Typography
      data-testid="patient-search-no-results"
      style={{
        margin: '48px 0px 48px 8px',
        fontWeight: 'bold',
      }}
      variant="h6">
      {COPY.NO_RESULTS}
      &nbsp;{`${lineText}`}
    </Typography>
  );
};

const SearchTips = ({ resultsFound, searchTerm }: { resultsFound: boolean; searchTerm: boolean }): JSX.Element => {
  const message = resultsFound ? COPY.CANT_FIND_SEARCH_TIP : COPY.SEARCH_TIPS;

  return (
    <Stack typography="body1" color="grey.600" paddingBottom={8} data-testid="patient-search-tips">
      <Box>{message}</Box>
      <Box component="ul">
        {searchTerm && (
          <Stack direction="row" gap={1} alignItems="center">
            <BulletPoint />
            {COPY.CHECK_SPELLING}
          </Stack>
        )}
        <Stack direction="row" gap={1} alignItems="center">
          <BulletPoint />
          {COPY.NEW_MOSAIQ_PATIENT_WAIT}
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <BulletPoint />
          {COPY.NAME_OR_ID_MESSAGE}
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <BulletPoint />
          {COPY.CLEAR_STATUS_FILTER}
        </Stack>
      </Box>
    </Stack>
  );
};

const SearchResults = ({
  data,
  primaryRole,
  handleGetPINModalOpen,
  buttonLoadingId,
  handleOptInModalOpen,
  optInModalLoadingId,
  history,
}: {
  data: PatientSearchQueryResultData;
  primaryRole: string;
  handleGetPINModalOpen: (patientItem: PatientSearchQueryItem, formType?: FormType) => Promise<any>;
  buttonLoadingId: string;
  handleOptInModalOpen: (patientItem: PatientSearchQueryItem) => Promise<any>;
  optInModalLoadingId: string;
  history: History;
}): JSX.Element | undefined => {
  const psoOrSuperuser = [PSO, SUPERUSER].includes(primaryRole);
  const isNurse = primaryRole === NURSE;
  const searchResults = data.searchPatients
    .filter((patient) => Boolean(patient.ida))
    .map((patient: PatientSearchQueryItem, index: number): JSX.Element => {
      const managementPlansEnabled = patient.userProfile.patientFeatures.managementPlansEnabled;
      let message = '';
      let color: 'primary' | 'info' | 'warning' = 'primary';
      switch (patient.overallStatus) {
        case FormStatus.REG_SUBMITTED:
          message = isUs() ? 'Submitted' : 'Submitted to MQ';
          color = 'primary';
          break;
        case FormStatus.REG_REVIEW_REQUIRED:
          message = 'Review required';
          color = 'warning';
          break;
        case FormStatus.IN_PROGRESS:
          message = 'In progress';
          color = 'info';
          break;
        default:
          break;
      }
      return (
        <Stack key={index} id={`patient_${patient.ida}`} gap={1} data-testid="patient-search-result">
          <Box display="flex" gap={2}>
            <Typography variant="h5" fontWeight={700} data-testid="patient-search-name">
              {patient.firstName} {patient.lastName}
            </Typography>
            {psoOrSuperuser &&
              patient.tags.map(
                (tagItem: any, tagIndex: any): JSX.Element => (
                  <StyledTagStatus key={tagIndex}>{tagItem.tag.name}</StyledTagStatus>
                ),
              )}
            {psoOrSuperuser && message !== '' && <Chip label={message} variant="outlined" color={color} />}
          </Box>
          <Stack direction="row" gap={1}>
            <EMROutlined />
            <Typography color="grey.600" data-testid="emr-text">
              EMR: {patient.emrInstance || '-'}
            </Typography>
          </Stack>
          <Stack gap={5} direction="row">
            <Box width={270}>
              <ContentDetails title="Patient ID" value={patient.ida} />
              <ContentDetails
                title="Date of birth"
                value={DateTimeConverter.getFormattedDateAsDDMonthYYYY(
                  patient.dob,
                  import.meta.env.REACT_APP_REGION as Region,
                )}
              />
              <ContentDetails title="Phone" value={patient.primaryPhoneFormatted} />
            </Box>
            <Box>
              <ContentDetails
                title="Address"
                titleAutoWidth
                value={isAddressValid(patient.address) && <Address address={patient.address} />}
              />
            </Box>
            <Stack direction="row" gap={2}>
              {psoOrSuperuser && (
                <>
                  <Stack alignItems="center" gap={1}>
                    <LoadingButton
                      variant="outlined"
                      size="large"
                      data-testid="form-access-button"
                      onClick={(e): void => {
                        e.preventDefault();
                        handleGetPINModalOpen(patient);
                      }}
                      loading={patient.id === buttonLoadingId}>
                      Form access
                    </LoadingButton>
                    <RegistrationSignUpStatus patient={patient} />
                  </Stack>
                  {!isUs() && (
                    <Stack alignItems="center" gap={1} data-testid="patient-search-opt-in">
                      <LoadingButton
                        variant="outlined"
                        size="large"
                        data-testid="patient-search-portal-access"
                        onClick={(e): void => {
                          e.preventDefault();
                          handleOptInModalOpen(patient);
                        }}
                        loading={patient.id === optInModalLoadingId}>
                        Portal access
                      </LoadingButton>
                      <PXSignUp signedUp={patient.pxOptedIn} />
                    </Stack>
                  )}
                </>
              )}
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  data-testid="patient-form-summary"
                  onClick={(e): void => {
                    e.preventDefault();
                    history.push({
                      pathname: (psoOrSuperuser ? '/navigator/' : '/') + `patient/${patient.id}/summary`,
                      state: { primaryRole: primaryRole },
                    });
                  }}>
                  {psoOrSuperuser ? 'View summary' : 'Assessments'}
                </Button>
              </div>
              {isNurse && managementPlansEnabled && (
                <div>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={(e): void => {
                      e.preventDefault();
                      history.push({
                        pathname: `patient/${patient.id}/management`,
                        state: { primaryRole: primaryRole },
                      });
                    }}>
                    Management plans
                  </Button>
                </div>
              )}
            </Stack>
          </Stack>
        </Stack>
      );
    });
  return (
    <Stack gap={2} divider={<Divider />}>
      {searchResults}
    </Stack>
  );
};

const isAddressValid = (address: PatientSearchQueryItemAddress): boolean => {
  return !!address && !!(address.line1 || address.city || address.state || address.country || address.postcode);
};

const Address = ({ address }: { address: PatientSearchQueryItemAddress }): JSX.Element => {
  const { line1, line2, city, state, country, postcode } = address;

  return (
    <div>
      {`${line1},`}
      <br />
      {line2 && (
        <>
          {`${line2},`}
          <br />
        </>
      )}
      {`${city},`}
      <br />
      {state} {country} {postcode}
    </div>
  );
};

const castToNumber = (stringArray: string[]): number[] => {
  return stringArray.map((option) => parseInt(option));
};

const SearchResultsWrapper = ({
  searchTerm,
  selectedRegistrations,
  selectedLocations,
  selectedTags,
  primaryRole,
  buttonLoadingId,
  history,
  optInModalLoadingId,
  handleGetPINModalOpen,
  handleOptInModalOpen,
}: {
  searchTerm?: string;
  selectedRegistrations: string[];
  selectedLocations: string[];
  selectedTags: string[];
  primaryRole: string;
  buttonLoadingId: string;
  history: History;
  optInModalLoadingId: string;
  handleGetPINModalOpen: (patientItem: PatientSearchQueryItem, form?: string) => Promise<any>;
  handleOptInModalOpen: (patientItem: PatientSearchQueryItem) => Promise<any>;
}): JSX.Element => {
  const {
    loading,
    error: queryError,
    data: queryData,
  } = useQuery(PATIENT_SEARCH, {
    variables: {
      searchTerm: searchTerm || '',
      selectedFilter: selectedRegistrations,
      locationFilter: castToNumber(selectedLocations),
      tagFilter: castToNumber(selectedTags),
    },
  });
  if (loading) {
    return <LoadingSpinner />;
  }

  if (queryError) {
    <NoSearchResults searchTerm={searchTerm} />;
  }

  return (
    <Stack borderColor="grey.300" padding={4} gap={2} divider={<Divider />}>
      {queryData?.searchPatients?.length ? (
        <SearchResults
          data={queryData}
          primaryRole={primaryRole}
          handleGetPINModalOpen={handleGetPINModalOpen}
          buttonLoadingId={buttonLoadingId}
          handleOptInModalOpen={handleOptInModalOpen}
          optInModalLoadingId={optInModalLoadingId}
          history={history}
        />
      ) : (
        <NoSearchResults searchTerm={searchTerm} />
      )}
      <SearchTips
        resultsFound={Boolean(queryData && queryData.searchPatients && queryData.searchPatients.length > 0)}
        searchTerm={Boolean(searchTerm)}
      />
    </Stack>
  );
};

type PatientSearchProps = RouteComponentProps;

const PatientSearch = ({ history }: PatientSearchProps): JSX.Element => {
  const { search: locationSearchParams } = useLocation();
  const { loading: filtersLoading, error: filtersError, data: filtersData } = useQuery(SEARCH_FILTERS_REF_DATA_QUERY);
  const { data: userData } = useQuery(GET_USER_DETAILS, { variables: { roles: ['PSO'], hasOther: true } });
  const { data: tagOptionsData, loading: tagOptionsLoading } = useQuery(GET_TAG_OPTIONS);
  const client = useApolloClient();

  const [searchTerm, setSearchTerm] = useState('');
  const [proxySearchTerm, setProxySearchTerm] = useState('');
  const [selectedRegistrations, setSelectedRegistrations] = useState<string[]>(
    JSON.parse(sessionStorage.getItem(LSKey.REGISTRATIONS) || '[]'),
  );
  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    JSON.parse(sessionStorage.getItem(LSKey.LOCATIONS) || '[]'),
  );
  const [selectedTags, setSelectedTags] = useState<string[]>(JSON.parse(sessionStorage.getItem(LSKey.TAGS) || '[]'));
  const [buttonLoadingId, setButtonLoadingId] = useState('');
  const [optInModalLoadingId, setOptInModalLoadingId] = useState('');
  const [selectedPatient, setSelectedPatient] = useState<PatientSearchQueryItem>(EMPTY_PATIENT);
  const [selectedPatientOptIn, setSelectedPatientOptIn] = useState<PatientOptIn>(EMPTY_PATIENT_OPT_IN);
  const [registrationInviteModalOpen, setRegistrationInviteModalOpen] = useState(false);
  const [optInModalOpen, setOptInModalOpen] = useState(false);
  const [isOptInMutationLoading, setIsOptInMutationLoading] = useState(false);
  const [lockErrorModalOpen, setLockErrorModalOpen] = useState(false);
  const [lockErrorText, setLockErrorText] = useState('');

  const primaryRole = userData?.user?.primaryRole;
  const allowedLocations = userData?.allowedLocations;
  const isPractitioner = userData?.user?.isPractitioner;
  const isNurse = primaryRole === NURSE;
  const isPsoOrSuperuser = [PSO, SUPERUSER].includes(primaryRole);

  const registrationOptions = filtersData?.searchFiltersRefData?.map((reg: { name: string; appKey: string }) => ({
    label: isUs() && reg.name === COPY.SUBMITTED_TO_MOSAIQ ? 'Submitted' : reg.name,
    value: reg.appKey,
  }));

  const locationOptions = allowedLocations?.map((loc: { alias: string; id: string }) => ({
    label: loc.alias,
    value: loc.id,
  }));

  const tagFilterOptions = tagOptionsData?.tagOptions?.map((tag: { name: string; id: string }) => ({
    label: tag.name,
    value: tag.id,
  }));

  const filterOrTermSelected =
    !!proxySearchTerm || !!selectedRegistrations.length || !!selectedLocations.length || !!selectedTags.length;

  useEffect(() => {
    const params = new URLSearchParams(locationSearchParams);
    const query = params.get('query') || '';
    setProxySearchTerm(query);
    setSearchTerm(query); // so it auto-fills from the url
  }, [locationSearchParams]);

  useEffect(() => {
    sessionStorage.setItem(LSKey.REGISTRATIONS, JSON.stringify(selectedRegistrations));
  }, [selectedRegistrations]);

  useEffect(() => {
    sessionStorage.setItem(LSKey.LOCATIONS, JSON.stringify(selectedLocations));
  }, [selectedLocations]);

  useEffect(() => {
    sessionStorage.setItem(LSKey.TAGS, JSON.stringify(selectedTags));
  }, [selectedTags]);

  const getLockDetails = async (patientItem: PatientSearchQueryItem): Promise<any> => {
    const result: { data: { patient: Patient } } = await client.query({
      query: RECORD_LOCK_QUERY,
      fetchPolicy: 'no-cache',
      variables: { id: patientItem.id },
    });

    const {
      data: { patient },
    } = result;

    if (patient && patient.lock && !patient.lock.readOnly) {
      return { readOnly: true, lockedByName: undefined }; // lock was aquired
    }

    return {
      readOnly: false,
      lockedByName: patient.lock ? patient.lock.lockedByName : 'a staff member',
    }; // lock wasn't aquired
  };

  const handleOptInModalOpen = async (patientItem: PatientSearchQueryItem): Promise<any> => {
    setOptInModalLoadingId(patientItem.id);

    const lockDetails = await getLockDetails(patientItem);

    if (lockDetails.readOnly) {
      setSelectedPatient(patientItem);
      setOptInModalLoadingId('');
      setOptInModalOpen(true);
      setSelectedPatientOptIn({
        id: patientItem.id,
        firstName: patientItem.firstName,
        lastName: patientItem.lastName,
        pxOptedIn: patientItem.pxOptedIn,
        primaryPhone: patientItem.primaryPhone,
        email: patientItem.email,
        pxOptOutComment: patientItem.pxOptOutComment,
        emrInstance: patientItem.emrInstance,
      });
    } else {
      openErrorModalAndResetLoading(lockDetails.lockedByName);
    }
  };

  const handleGetPINModalOpen = async (patientItem: PatientSearchQueryItem): Promise<any> => {
    setButtonLoadingId(patientItem.id);

    const lockDetails = await getLockDetails(patientItem);

    if (lockDetails.readOnly) {
      setRegistrationInviteModalOpen(true);
      setSelectedPatient(patientItem);
      setButtonLoadingId('');
      releaseLock();
    } else {
      openErrorModalAndResetLoading(lockDetails.lockedByName);
    }
  };

  const openErrorModalAndResetLoading = (lockedByName?: string): void => {
    if (lockedByName) {
      const RECORD_IN_USE_BY = COPY.RECORD_IN_USE_BY;
      const RECORD_IN_USE_NO_VIEW = COPY.RECORD_IN_USE_NO_VIEW;
      const lockErrorText = `${RECORD_IN_USE_BY} ${lockedByName}${RECORD_IN_USE_NO_VIEW}`;
      setLockErrorModalOpen(true);
      setLockErrorText(lockErrorText);
      setOptInModalLoadingId('');
      setButtonLoadingId('');
    }
  };

  const releaseLock = async (): Promise<any> => {
    if (selectedPatient.id === '') {
      return;
    }
    await client.mutate({
      mutation: RELEASE_LOCK_MUTATION,
      variables: { accessPatientId: selectedPatient.id },
    });
  };

  const resetOptInModalStates = (): void => {
    setOptInModalOpen(false);
    setSelectedPatientOptIn(EMPTY_PATIENT_OPT_IN);
    setSelectedPatient(EMPTY_PATIENT);
  };

  const handleSaveOptIn = (graphData: GraphUpdate[], optedIn: boolean, shouldSubmit: boolean): void => {
    let mutationVariables: { [key: string]: string | boolean } = { id: selectedPatientOptIn.id, pxOptedIn: optedIn };
    let email = '';

    const mutation = optedIn
      ? gql`
          mutation UpdatePatient($id: ID!, $pxOptedIn: Boolean, $email: String, $primaryPhone: String) {
            updatePatient(id: $id, pxOptedIn: $pxOptedIn, email: $email, primaryPhone: $primaryPhone) {
              patient {
                id
                pxOptedIn
                email
                primaryPhoneFormatted
                primaryPhone
              }
            }
          }
        `
      : gql`
          mutation UpdatePatient($id: ID!, $pxOptedIn: Boolean, $pxOptOutComment: String) {
            updatePatient(id: $id, pxOptedIn: $pxOptedIn, pxOptOutComment: $pxOptOutComment) {
              patient {
                id
                pxOptedIn
                pxOptOutComment
              }
            }
          }
        `;

    if (optedIn) {
      let primaryPhone = '';
      graphData.forEach((graphItem: GraphUpdate) => {
        if (graphItem.key === 'email') {
          email = graphItem.value as string;
        } else if (graphItem.key === 'primaryPhone') {
          primaryPhone = graphItem.value as string;
        }
      });

      if (email === '' || primaryPhone === '') {
        return;
      }
      mutationVariables = { ...mutationVariables, email, primaryPhone };
    } else {
      const [graphItem] = graphData;

      // The key is not a px comment, so break out and stop the save, as there is an error here.
      if (graphItem.key !== 'pxOptOutComment') {
        return;
      }
      mutationVariables = { ...mutationVariables, pxOptOutComment: graphItem.value };
    }

    client
      .mutate({ mutation, variables: { ...mutationVariables } })
      .then(() => {
        if (optedIn) {
          handleSendPXEmailInvitation(email);
          if (shouldSubmit) {
            submitRegistration(false);
          }
        }
        setIsOptInMutationLoading(false);
      })
      .then(() => {
        resetOptInModalStates();
      });
  };

  const handleSendPXEmailInvitation = (patientEmail?: string): void => {
    if (patientEmail) {
      client
        .mutate({
          mutation: SEND_PX_EMAIL_INVITATION,
          variables: {
            recipientEmail: patientEmail,
          },
        })
        .then((result): void => {
          logger.info('Invite link is:', result.data.sendPxEmailInvitation.inviteLink);
        });
    }
  };

  const submitRegistration = (includePdf = false, fromHomeRego = false): void => {
    client
      .mutate({
        mutation: CREATE_SUBMISSION,
        variables: {
          patientID: selectedPatientOptIn.id,
          includePdf: includePdf,
          updateFormStatus: false,
          fromHomeRego: fromHomeRego,
        },
      })
      .then((response): void => {
        if (response.data?.createSubmission?.submission?.pdf) {
          logger.debug(
            'submitRegistration',
            `Response came back: /server/media/${response.data.createSubmission.submission.pdf}`,
          );
        }
      });
  };

  const handleSearch = (): void => {
    setProxySearchTerm(searchTerm);
    history.replace(`/search?query=${searchTerm}`);
  };

  if (!isPractitioner && primaryRole === NURSE) {
    // TODO: this can probably be lifted to a wrapper
    const isPractitionerError = COPY.IS_PRACTITIONER_ERROR;
    const supportEmail = CurrentAppConfig.SupportEmail;
    const generateEmail = () => {
      window.location.href = `mailto:${supportEmail}`;
    };
    const logout = () => {
      client.clearStore().then(() => {
        client.writeQuery({
          query: gql`
            query {
              contentShown
            }
          `,
          data: {
            contentShown: false,
          },
        });
      });
      client
        .mutate({
          mutation: LOGOUT_MUTATION,
          variables: {},
        })
        .then((response) => {
          if (response.data.logout.errors !== null) {
            logger.error('logout', 'Unable to logout');
            logger.error('logout', response.data.logout.errors);
            return;
          }
          navigateToExternalURL('/sso/logout');
        });
    };
    return (
      <StandardDialog
        open
        title={COPY.CANT_FIND_ACC_DETAILS}
        submitText={'Logout & contact us'}
        onSubmit={() => {
          generateEmail();
          logout();
        }}>
        <ModalContentSubmissionError text={isPractitionerError} emailLink={supportEmail} />
      </StandardDialog>
    );
  }

  if (filtersLoading || tagOptionsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Box
        sx={{
          top: isPsoOrSuperuser ? '80px' : 0,
          position: 'sticky',
          padding: 4,
          paddingBottom: 1,
          paddingTop: 3,
          backgroundColor: 'background.paper',
          borderBottom: '1px solid',
          borderBottomColor: 'grey.300',
          overflow: 'auto',
          zIndex: 10,
        }}>
        {isNurse && (
          <Typography variant="h5" marginBottom={3}>
            Patient Search
          </Typography>
        )}
        {(isPsoOrSuperuser || isNurse) && (
          <>
            <Stack direction="row" gap={2}>
              <TextField
                type="text"
                data-testid="patient-search-input"
                name="q"
                placeholder={COPY.SEARCH_PLACEHOLDER || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                sx={{
                  flex: 1,
                  maxWidth: '872px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button size="large" data-testid="patient-search" variant="contained" onClick={handleSearch}>
                Search
              </Button>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              marginTop={2}
              maxWidth={isPsoOrSuperuser ? '1150px' : '300px'}>
              <SearchFilter
                showAlert={Boolean(selectedRegistrations.length || selectedLocations.length || selectedTags.length)}>
                <Stack paddingY={1} paddingX={2} gap={2} data-testid="search-filters">
                  {!isNurse && (
                    <div>
                      <SearchMultiSelectField
                        selectAllEnabled={true}
                        selectAllLabel="Select all"
                        selectedOptionsUpdated={setSelectedRegistrations}
                        resetSearchResults={() => {}}
                        defaultSelectedOptions={selectedRegistrations}
                        options={registrationOptions || []}
                        placeholder="Registration"
                        allSelected={selectedRegistrations?.length === registrationOptions?.length}
                        allSelectedLabel="All registration types"
                      />
                    </div>
                  )}
                  <div>
                    <SearchMultiSelectField
                      selectAllEnabled={true}
                      selectAllLabel="Select all"
                      selectedOptionsUpdated={setSelectedLocations}
                      resetSearchResults={() => {
                        return null;
                      }}
                      defaultSelectedOptions={selectedLocations}
                      options={locationOptions}
                      placeholder="Location"
                      allSelected={selectedLocations?.length === locationOptions?.length}
                      allSelectedLabel="All locations"
                    />
                  </div>
                  {!isUs() && (
                    <div>
                      <SearchMultiSelectField
                        selectAllEnabled={true}
                        selectAllLabel="Select all"
                        selectedOptionsUpdated={setSelectedTags}
                        resetSearchResults={() => {
                          return null;
                        }}
                        defaultSelectedOptions={selectedTags}
                        options={tagFilterOptions}
                        placeholder="Tag"
                        allSelected={selectedTags?.length === tagFilterOptions?.length}
                        allSelectedLabel="All tags"
                      />
                    </div>
                  )}
                </Stack>
              </SearchFilter>
              {(isPsoOrSuperuser || (isNurse && allowedLocations.length > 0)) && (
                <Button
                  variant="text"
                  data-testid="clear-filter"
                  onClick={(): void => {
                    setSelectedRegistrations([]);
                    setSelectedLocations([]);
                    setSelectedTags([]);
                  }}>
                  Clear filters
                </Button>
              )}
            </Stack>
          </>
        )}
        <br />
      </Box>
      {filterOrTermSelected && (
        <SearchResultsWrapper
          searchTerm={proxySearchTerm}
          selectedRegistrations={selectedRegistrations}
          selectedLocations={selectedLocations}
          selectedTags={selectedTags}
          primaryRole={primaryRole}
          buttonLoadingId={buttonLoadingId}
          history={history}
          optInModalLoadingId={optInModalLoadingId}
          handleGetPINModalOpen={handleGetPINModalOpen}
          handleOptInModalOpen={handleOptInModalOpen}
        />
      )}
      {registrationInviteModalOpen && (
        <PatientAccessModal
          patient={selectedPatient}
          isOpen={registrationInviteModalOpen}
          onClose={() => {
            setRegistrationInviteModalOpen(false);
          }}
        />
      )}
      {optInModalOpen && (
        <ModalOptIn
          key={`modal-opt-in-${selectedPatientOptIn?.id}`}
          patient={selectedPatientOptIn}
          isOpen={optInModalOpen}
          dismissFunction={(): void => {
            if (!isOptInMutationLoading) {
              releaseLock();
              // Reset to the default state
              resetOptInModalStates();
            }
          }}
          saveFunction={(graphData: GraphUpdate[], optedIn: boolean, shouldSubmit: boolean): void => {
            handleSaveOptIn(graphData, optedIn, shouldSubmit);
          }}
          dismissLock={() => setIsOptInMutationLoading(false)}
          setMutationLoading={() => setIsOptInMutationLoading(true)}
          patientIda={selectedPatient.ida}
          address={selectedPatient.address}
          dob={selectedPatient.dob}
        />
      )}
      <ModalInfo
        title={'Record in use'}
        text={lockErrorText}
        isOpen={lockErrorModalOpen}
        dismissFunction={(): void => {
          setLockErrorModalOpen(false);
        }}
      />
    </>
  );
};

export default PatientSearch;
