// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { ROInlinePatientSearch } from 'op-components';
import NavigationDropDown from 'op-components/NavigationDropDown/NavigationDropDown';
import { RoleType } from 'op-enums';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Notification, SearchWhite } from 'shared-components/images';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { isOncologist, isViewer } from './HeaderBar';
import { HAS_LATEST_NOTIFICATION, TENANT_CONFIG_QUERY, UPDATE_USER_NOTIFICATION } from './HeaderBarQueries';
import { isUs, isDemo } from 'op-utils';
import OutageBanner from 'op-components/Banner/Outage';
import { CustomTheme, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const { RO, CAREPLANVIEWER, MO, PSO } = RoleType;

interface Props {
  children: any;
  primaryRole: string;
  isPso: boolean;
  firstName: string;
  lastName: string;
  recentPatients?: any;
  styledProps?: boolean;
  isInsightsUser: boolean;
  toggleSearchModal?: () => void;
}

interface NavOptionType {
  label: string;
  linkTo: string;
  id: string;
}

interface NavLinks {
  option: NavOptionType;
  key: string;
}

const HeaderContainer = styled(Stack)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.primary.main};
  height: 56px;
  z-index: 7000;
  position: relative;
  box-shadow: 0px 1px 2px ${(props) => props.theme.palette.grey[600]};
  align-items: center;
  padding: 0px 0px 0px 20px;
`;

const NavLinksContainer = styled(Stack)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 81px;
  height: 56px;

  a {
    text-decoration: unset;
  }

  a:hover {
    color: white;
    text-decoration: unset;
  }
`;

const StyledLink = styled(NavLink)`
  color: white;
  border-bottom: 3px solid transparent;
  padding-top: 3px;
  :hover {
    color: white;
    text-decoration: none;
    border-bottom: 3px solid white;
  }

  text-decoration: unset;
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledLatestUpdatesLink = styled('a')`
  color: white;
  border-bottom: 3px solid transparent;
  padding-top: 3px;
  :hover {
    color: white;
    text-decoration: none;
    border-bottom: 3px solid white;
  }
  text-decoration: unset;
  height: 100%;
  display: flex;
  align-items: center;
`;

const NotificationIcon = styled((props: any) => <Notification title="notificationIcon.svg" {...props} />)`
  margin-top: -5px;
`;

const NotificationCircle = styled('div')`
  margin: -24px 0px 0px 130px;
`;

const StyledName = styled('div')`
  color: white;
  font-size: 16px;
  width: 150px;
  text-align: right;
`;

const SearchIcon = styled((props: any) => <SearchWhite {...props} />)`
  cursor: pointer;
  margin-right: 10px;
  height: 56px;
  width: 30px;
  :hover {
    color: white;
    text-decoration: none;
    border-bottom: 3px solid white;
  }
`;

const InternalHeader = ({
  children,
  primaryRole,
  isPso,
  isInsightsUser,
  firstName,
  lastName,
  recentPatients,
}: Props): JSX.Element => {
  const theme = useTheme<CustomTheme>();
  const windowSize = useWindowSize();
  const isSmallDisplay = windowSize.width < 1440;
  const [isInlineSearchVisible, setIsInlineSearchVisible] = useState(false);
  const { data: notificationData, loading: notificationLoading } = useQuery(HAS_LATEST_NOTIFICATION);
  const showNotificationUpdate = notificationLoading
    ? false
    : !notificationData?.hasLatestNotification?.hasLatest && isPso;
  const [updateLatestNotification] = useMutation(UPDATE_USER_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: HAS_LATEST_NOTIFICATION }],
  });
  const { data: tenantConfigData, loading: tenantConfigLoading } = useQuery(TENANT_CONFIG_QUERY);

  const ROLE_TO_HOME_REDIRECT_MAPPING = {
    [RO]: '/radiation',
    [CAREPLANVIEWER]: '/radiation',
    [MO]: '/medonc',
    [PSO]: '/search',
  };
  const home = ROLE_TO_HOME_REDIRECT_MAPPING[primaryRole] || '/search';

  const redCircle = (
    <NotificationCircle>
      <svg className="notification-red-circle" height="15" width="15">
        <circle cx="7.5" cy="7.5" r="7.5" fill={theme.palette.error.main} />
        <text fontSize="0.8em" x="28%" y="50%" fill="white" dy=".35em">
          1
        </text>
      </svg>
    </NotificationCircle>
  );

  const isRo = primaryRole === RO;

  const navOptions: NavOptionType[] = [
    {
      label: isRo ? 'APPOINTMENTS' : 'DASHBOARD',
      linkTo: home,
      id: 'appointments',
    },
  ];
  if (isRo) {
    navOptions.push({
      label: 'CAREPLANS',
      linkTo: '/radiation/careplans',
      id: 'careplans',
    });
  }
  if (isDemo) {
    navOptions.push({
      label: 'TRACKER',
      linkTo: '/radiation/patient-tracker',
      id: 'dashboard',
    });
  }
  if ((tenantConfigData?.tenantConfig?.insightsVisible && isInsightsUser) || isDemo) {
    navOptions.push({
      label: 'INSIGHTS',
      linkTo: '/radiation/insights',
      id: 'insights',
    });
  }
  if (!isUs()) {
    navOptions.push({
      label: 'LATEST UPDATES',
      linkTo: 'https://genesiscare.sharepoint.com/sites/Oncologyportal/SitePages/Latest-Release-information.aspx',
      id: 'latestUpdates',
    });
  }

  const toggleInlineSearchModal = (e: React.MouseEvent<HTMLElement>) => {
    setIsInlineSearchVisible((visible) => !visible);
    e.stopPropagation();
  };

  const HeaderNavLink = (props: NavLinks): JSX.Element | null => {
    const { linkTo, label, id } = props.option;

    const handleLatestUpdatesClick = () => {
      if (!showNotificationUpdate) return;
      updateLatestNotification();
    };

    if (id === 'latestUpdates') {
      // Do not render Latest updates for ROs (only for PSO, PN and Nurses)
      if ([RO, CAREPLANVIEWER, MO].includes(primaryRole)) {
        return null;
      }

      return (
        <StyledLatestUpdatesLink href={linkTo} target="_blank" id="sharepoint-link">
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              if (id === 'latestUpdates') {
                handleLatestUpdatesClick();
              }
            }}>
            <Typography color={theme.palette.primary.contrastText} variant="button">
              {label}
            </Typography>
            <NotificationIcon />
            {showNotificationUpdate && redCircle}
          </Stack>
        </StyledLatestUpdatesLink>
      );
    }

    return (
      <StyledLink exact to={linkTo} activeStyle={{ borderBottom: '3px solid white' }} id={id}>
        <Typography color={theme.palette.primary.contrastText} variant="button">
          {label}
        </Typography>
      </StyledLink>
    );
  };
  return (
    <>
      <OutageBanner />
      <HeaderContainer direction="row" id="internal-header">
        <Link to={home} id="gc-logo-navigator">
          {theme?.custom?.logo}
        </Link>
        <NavLinksContainer gap="32px" direction="row">
          {navOptions.map(
            (option: NavOptionType): JSX.Element => (
              <HeaderNavLink option={option} key={option.label} />
            ),
          )}
        </NavLinksContainer>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          {isOncologist(primaryRole) ? (
            <div role="presentation" className={`inline-search-div ${isInlineSearchVisible ? 'active-search' : ''}`}>
              {!isInlineSearchVisible && <SearchIcon data-testid="search-icon" onClick={toggleInlineSearchModal} />}
              {isInlineSearchVisible && (
                <ROInlinePatientSearch
                  primaryRole={primaryRole}
                  closeSearch={() => setIsInlineSearchVisible(false)}
                  recentPatients={recentPatients}
                />
              )}
            </div>
          ) : (
            !isViewer(primaryRole) && (
              <StyledName id="logged-in-user-name">
                {isSmallDisplay ? firstName[0] + '. ' + lastName : firstName + ' ' + lastName}
              </StyledName>
            )
          )}
          {
            // @ts-ignore
            <NavigationDropDown name={firstName + ' ' + lastName} forROPortal={isOncologist(primaryRole)} />
          }
        </div>
      </HeaderContainer>
      {children && React.cloneElement(children, { primaryRole })}
    </>
  );
};

export default InternalHeader;
