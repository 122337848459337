import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartStackedColumn from '../Charts/ChartStackedColumn';
import SubChartBar from '../Charts/SubCharts/SubChartBar';
import SubChartStackedBarPercentTotal from '../Charts/SubCharts/SubChartStackedBarPercentTotal';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { DataFunctions } from '../../Utils/dataFunctions';

const seriesColorMapping: { [key: string]: string } = {
  'IMRT/VMAT': 'rgba(170, 161, 253)',
  Stereotactic: 'rgba(62, 167, 178)',
  Conformal: 'rgba(62, 112, 178)',
  Brachy: 'rgba(255, 189, 91)',
  SXRT: 'rgba(255, 91, 91)',
  Complex: 'rgba(251, 106, 118)',
};

const TechniqueUtilisation = ({
  data,
  subChartData,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  ...rest
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartStackedColumn({
      selectedPoint: rest.filterOverride,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      aggregation: dateAggregationMap[dateRange],
      dateRange: dateRange,
      leftSeriesFields: DataFunctions.aggregateStackedBarData(
        data,
        'technique',
        dateAggregationMap[dateRange!],
        'Complex Percentage',
        ['IMRT/VMAT', 'Stereotactic', 'Conformal', 'Complex', 'Brachy', 'Unspecified'],
      )[0].slice(0, -1),
      rightSeriesFields: ['submittedAt', 'Complex Percentage'],
      opacity: 0.75,
      stacking: 'percent',
      seriesColorMapping,
      ...rest,
    }).components?.[0] ?? {},
    SubChartBar({
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      yAxisMax: DataFunctions.aggregateDataMax(subChartData, 'tumourStream') * 2,
      dataLength: DataFunctions.aggregateData(subChartData, 'tumourStream').length - 1,
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'tumourStream'),
      ...rest,
    }).components?.[0] ?? {},
    SubChartStackedBarPercentTotal({
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      dataLength: DataFunctions.aggregateTechniqueByField(subChartData, 'createdBy').length - 1,
      seriesFields: DataFunctions.aggregateTechniqueByField(subChartData, 'createdBy')[0].map((item) =>
        item.toString(),
      ),
      dataTotal: DataFunctions.aggregateDataSum(subChartData, 'createdBy'),
      ...rest,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: DataFunctions.aggregateStackedBarData(
          data,
          'technique',
          dateAggregationMap[dateRange!],
          'Complex Percentage',
        ),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: DataFunctions.aggregateData(
          subChartData,
          'tumourStream',
          seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT,
        ),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: DataFunctions.aggregateTechniqueByField(
          subChartData,
          'createdBy',
          seeMoreBottomRight ? undefined : SUB_CHART_LIMIT,
          ['IMRT/VMAT', 'Stereotactic', 'Conformal', 'Complex', 'Brachy', 'Unspecified'],
        ),
      },
    },
  ],
});

export default TechniqueUtilisation;
