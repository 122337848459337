import { gql } from '@apollo/client';

export const USER_QUERY = gql`
  {
    user {
      id
      email
      firstName
      lastName
      isPso
      isSuperuser
      isShowcaseUser
      primaryRole
      isInsightsUser
    }
  }
`;

export const LOGGED_IN_PATIENT_QUERY = gql`
  {
    patient {
      id
      ida
    }
  }
`;
