// eslint-disable-next-line no-use-before-define
import { useQuery } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { useErrorModalContext } from 'op-contexts';
import { RoleType } from 'op-enums';
import { USER_QUERY } from 'op-graphql';
import DefaultHeader from './DefaultHeader';
import InternalHeader from './InternalHeader';
import PatientFacingHeader from './PatientFacingHeader';

const { PSO, MO, RO, SUPERUSER, DOCTOR, NURSE, CAREPLANVIEWER, OTHER } = RoleType;

interface Props {
  children?: any;
  defaultHeader?: boolean;
  headingText?: string | null;
  menuButton?: boolean;
  menuButtonText?: string;
  toggleSearchModal?: any;
  recentPatients?: any;
  styledProps?: boolean;
  preventQuery?: boolean;
  hasRendered?: () => void;
}

export const isOncologist = (primaryRole: string): boolean => {
  return primaryRole === RO || primaryRole === MO;
};

export const isViewer = (primaryRole: string): boolean => primaryRole === CAREPLANVIEWER;

const HeaderBar = ({
  children,
  defaultHeader,
  headingText,
  menuButton,
  recentPatients,
  styledProps,
  preventQuery = false,
  menuButtonText,
  hasRendered,
}: Props): JSX.Element => {
  const [showDefaultHeader, setShowDefaultHeader] = useState(false);
  const { setError } = useErrorModalContext();

  // Skip is used to stop the query running when a user has logged out, which will cause a graphql logged in error
  const { data: dataUserQuery, error: errorUserQuery } = useQuery(USER_QUERY, {
    fetchPolicy: 'network-only',
    skip: preventQuery,
  });

  // Render default header if errored
  useEffect(() => {
    if (errorUserQuery) {
      setShowDefaultHeader(true);
    }
  }, [errorUserQuery]);

  useEffect(() => {
    if (dataUserQuery) {
      hasRendered && hasRendered();
    }
  }, [dataUserQuery]);

  if (defaultHeader || showDefaultHeader) {
    return <DefaultHeader patientFacing={defaultHeader} headingText={headingText} />;
  }

  if (dataUserQuery && dataUserQuery.user.primaryRole) {
    const displaySearch =
      [PSO, SUPERUSER, NURSE].includes(dataUserQuery.user.primaryRole) || dataUserQuery.user.isShowcaseUser;
    if (window.location.pathname === '/search' && !displaySearch) {
      return <Redirect to="/error" />;
    }

    const headerComponentMap = {
      [RO]: InternalHeader,
      [MO]: InternalHeader,
      [PSO]: InternalHeader,
      [NURSE]: InternalHeader,
      [SUPERUSER]: InternalHeader,
      [CAREPLANVIEWER]: InternalHeader,
      [DOCTOR]: DefaultHeader,
      [OTHER]: PatientFacingHeader,
    };

    const headerProps = {
      children: children,
      primaryRole: dataUserQuery.user.primaryRole,
      isPso: dataUserQuery.user.isPso,
      firstName: dataUserQuery.user.firstName,
      lastName: dataUserQuery.user.lastName,
      isInsightsUser: dataUserQuery.user.isInsightsUser,
      headingText: headingText,
      menuButton: menuButton,
      recentPatients: recentPatients,
      styledProps: styledProps,
      menuButtonText: menuButtonText,
    };

    const Card = headerComponentMap[dataUserQuery.user.primaryRole];

    return <Card {...headerProps} />;
  }

  return <Fragment />;
};

export default HeaderBar;
