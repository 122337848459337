import {
  COMPLEX_TECHNIQUES,
  getWeekNumber,
  sortFunctions,
  groupCategoriesIntoOther,
  ABBREVIATED_TYPES,
  countWeekdays,
  isSimAppointment,
  isTreatmentAppointment,
  isLinacLocation,
} from './utils';
import { InsightsDateAggregation } from 'op-enums';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

// AGGREGATE FUNCTIONS
const aggregateAverageFractions = (data: any, aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt, fractions, fractionDose }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toISOString().slice(0, 7);

    if (!acc[dateKey]) acc[dateKey] = { totalFractions: 0, totalFractionDose: 0, count: 0 };
    acc[dateKey].totalFractions += fractions;
    acc[dateKey].count += 1;
    acc[dateKey].totalFractionDose += fractionDose;

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((dateKey) => {
      // calling toLocalString() after sorting above to avoid firefox bug
      const formattedDateKey =
        aggregateBy === 'months'
          ? new Date(dateKey + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })
          : dateKey;

      return [
        isNaN(Number(formattedDateKey)) ? formattedDateKey : Number(formattedDateKey),
        aggregated[dateKey].totalFractions / aggregated[dateKey].count,
        aggregated[dateKey].totalFractionDose / aggregated[dateKey].count,
      ];
    });
};

const aggregateAverageSimToTreat = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const getDateKey = (date: Date, aggregateBy: string | InsightsDateAggregation) => {
    if (aggregateBy === 'days') {
      return new Date(date.setHours(12, 0, 0, 0)).getTime();
    }
    if (aggregateBy === 'weeks') {
      return `week_${getWeekNumber(date)}`;
    }
    return date.toISOString().slice(0, 7);
  };

  const patientTimes: { [patientId: string]: { simDate?: Date; treatDate?: Date } } = {};

  data.forEach((record) => {
    if (!record.appointment.patient) {
      return;
    }

    const patientId = record.appointment.patient.id;
    const submittedAt = new Date(record.submittedAt);

    if (!patientTimes[patientId]) {
      patientTimes[patientId] = {};
    }

    if (isSimAppointment(record.appointment)) {
      const currentSimDate = patientTimes[patientId]?.simDate;
      const currentTreatDate = patientTimes[patientId]?.treatDate;

      // Update simDate only if earlier than current simDate and treatDate
      if ((!currentSimDate || submittedAt < currentSimDate) && (!currentTreatDate || submittedAt < currentTreatDate)) {
        patientTimes[patientId].simDate = submittedAt;
      }
    }

    if (isTreatmentAppointment(record.appointment)) {
      const currentTreatDate = patientTimes[patientId]?.treatDate;
      const currentSimDate = patientTimes[patientId]?.simDate;

      // Update treatDate only if earlier than current treatDate and after simDate
      if ((!currentTreatDate || submittedAt < currentTreatDate) && (!currentSimDate || submittedAt > currentSimDate)) {
        patientTimes[patientId].treatDate = submittedAt;
      }
    }
  });

  const aggregated: { [key: string]: { sim_to_treat: number; count: number } } = {};

  for (const patientId in patientTimes) {
    const { simDate, treatDate } = patientTimes[patientId];
    if (simDate && treatDate) {
      const differenceInDays = countWeekdays(simDate, treatDate);
      const dateKey = getDateKey(simDate, aggregateBy);

      if (!aggregated[dateKey]) {
        aggregated[dateKey] = { sim_to_treat: 0, count: 0 };
      }

      aggregated[dateKey].sim_to_treat += differenceInDays;
      aggregated[dateKey].count += 1;
    }
  }

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => {
      // calling toLocalString() after sorting above to avoid firefox bug
      const formattedDateKey =
        aggregateBy === 'months'
          ? new Date(date + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })
          : date;

      return [
        isNaN(Number(formattedDateKey)) ? formattedDateKey : Number(formattedDateKey),
        parseFloat((aggregated[date].sim_to_treat / aggregated[date].count).toFixed(2)),
      ];
    });
};

const aggregateStackedBarData = (
  data: any,
  field: string,
  aggregateBy: string | InsightsDateAggregation,
  extraField?: string,
  sortOrder?: string[],
  groupSmallCategories = false,
) => {
  const aggregated = data.reduce((acc: any, { submittedAt, [field]: fieldValue }: any) => {
    const dateObj = new Date(submittedAt);

    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toISOString().slice(0, 7);

    if (!acc[dateKey]) acc[dateKey] = { date: dateKey, total: 0, extraTotal: 0 };
    if (!acc[dateKey][fieldValue]) acc[dateKey][fieldValue] = 0;

    acc[dateKey][fieldValue] += 1;
    acc[dateKey].total += 1;

    if (extraField && COMPLEX_TECHNIQUES.includes(fieldValue)) {
      acc[dateKey].extraTotal += 1;
    }

    return acc;
  }, {});

  const uniqueFieldValues = Array.from(new Set(data.map((d: { [key: string]: any }) => d[field]))) as string[];

  const sortedFieldValues = sortOrder
    ? uniqueFieldValues.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
    : uniqueFieldValues;

  const columnNames = [
    'submittedAt',
    ...sortedFieldValues.map((value) => value.charAt(0).toUpperCase() + value.slice(1)),
  ];
  if (extraField) columnNames.push(extraField);

  const rows = Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((dateKey) => {
      // calling toLocalString() after sorting above to avoid firefox bug
      const formattedDateKey =
        aggregateBy === 'months'
          ? new Date(dateKey + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })
          : dateKey;

      const row = [
        isNaN(Number(formattedDateKey)) ? formattedDateKey : Number(formattedDateKey),
        ...sortedFieldValues.map((value: unknown) => aggregated[dateKey][value as string] || 0),
      ];
      if (extraField) row.push((aggregated[dateKey].extraTotal / aggregated[dateKey].total) * 100);
      return row;
    });

  const groupedData = groupSmallCategories ? groupCategoriesIntoOther([columnNames, ...rows]) : [columnNames, ...rows];

  return groupedData;
};

const aggregateTechniquePercentage = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt, technique }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalPlans: 0, matchingTechniques: 0 };
    acc[dateKey].totalPlans += 1;
    if (COMPLEX_TECHNIQUES.includes(technique)) {
      acc[dateKey].matchingTechniques += 1;
    }
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      isNaN(Number(date)) ? date : Number(date),
      (aggregated[date].matchingTechniques / aggregated[date].totalPlans) * 100,
    ]);
};

const aggregateMonth = (data: any) => {
  const diagnosisTypes: string[] = [...new Set((data as { diagnosis: string }[]).map((item) => item.diagnosis))];
  const monthlyData = data.reduce((acc: any, { date, diagnosis }: any) => {
    const month = date.slice(0, 7);
    if (!acc[month])
      acc[month] = diagnosisTypes.reduce((typeAcc: any, type: string) => ({ ...typeAcc, [type]: 0 }), {});
    acc[month][diagnosis] += 1;
    return acc;
  }, {});

  return [
    ['Date', ...diagnosisTypes.map((type) => type.charAt(0).toUpperCase() + type.slice(1))],
    ...Object.keys(monthlyData)
      .sort((a, b) => new Date(a + '-01').getTime() - new Date(b + '-01').getTime())
      .map((month) => [month, ...diagnosisTypes.map((type) => monthlyData[month][type])]),
  ];
};

const aggregateAdherencePercentage = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt, adherence, fractionDose }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? (dateObj.setHours(12, 0, 0, 0) as number)
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalRecords: 0, adherentRecords: 0, peerReviewRecords: 0 };
    acc[dateKey].totalRecords += 1;
    if (adherence === true) {
      acc[dateKey].adherentRecords += 1;
    }

    if (fractionDose > 2.5) {
      acc[dateKey].peerReviewRecords += 1;
    }
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      isNaN(Number(date)) ? date : Number(date),
      (aggregated[date].adherentRecords / aggregated[date].totalRecords) * 100,
      aggregated[date].totalRecords - aggregated[date].adherentRecords,
      aggregated[date].peerReviewRecords,
    ]);
};

const aggregateAdherenceValues = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt, adherence, fractionDose }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalRecords: 0, adherentRecords: 0, peerReviewRecords: 0 };
    acc[dateKey].totalRecords += 1;
    if (adherence === true) {
      acc[dateKey].adherentRecords += 1;
    }

    if (fractionDose > 2.5) {
      acc[dateKey].peerReviewRecords += 1;
    }
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      isNaN(Number(date)) ? date : Number(date),
      aggregated[date].adherentRecords,
      aggregated[date].totalRecords - aggregated[date].adherentRecords,
      aggregated[date].peerReviewRecords,
    ]);
};

const aggregateAdherenceByField = (
  data: { diagnosis: string; adherence: boolean }[],
  field: string,
  limit?: number,
): [string, number, number][] => {
  const aggregated = data.reduce<
    Record<string, { totalRecords: number; adherentRecords: number; nonAdherentRecords: number }>
  >((acc, record: { [key: string]: any }) => {
    const key = record[field];
    if (!acc[key]) {
      acc[key] = { totalRecords: 0, adherentRecords: 0, nonAdherentRecords: 0 };
    }
    acc[key].totalRecords += 1;
    if (record.adherence === true) {
      acc[key].adherentRecords += 1;
    }
    if (record.adherence === false) {
      acc[key].nonAdherentRecords += 1;
    }
    return acc;
  }, {});

  const sortedTypes = Object.entries(aggregated).sort((a, b) => b[1].adherentRecords - a[1].adherentRecords);
  const limitedTypes = limit ? sortedTypes.slice(0, limit) : sortedTypes;

  return limitedTypes.map(([key, stats]): [string, number, number] => [
    key.charAt(0).toUpperCase() + key.slice(1),
    stats.adherentRecords,
    stats.totalRecords - stats.adherentRecords,
  ]);
};

const aggregateTechniqueByField = (
  data: Record<string, any>[],
  field: string,
  limit?: number,
  sortOrder?: string[],
): (string | number)[][] => {
  const aggregated = data.reduce<Record<string, Record<string, number>>>((acc, record) => {
    const key = record[field];
    const technique = record.technique;

    if (!acc[key]) {
      acc[key] = {};
    }

    if (!acc[key][technique]) {
      acc[key][technique] = 0;
    }

    acc[key][technique] += 1;

    return acc;
  }, {});

  const allTechniques = Array.from(new Set(Object.values(aggregated).flatMap((techniques) => Object.keys(techniques))));

  const sortedTechniques = sortOrder
    ? allTechniques.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
    : allTechniques;

  const headerRow = [field.charAt(0).toUpperCase() + field.slice(1), ...sortedTechniques];

  const sortedTypes = Object.entries(aggregated).sort(
    (a, b) =>
      Object.values(b[1]).reduce((sum, count) => sum + count, 0) -
      Object.values(a[1]).reduce((sum, count) => sum + count, 0),
  );
  const limitedTypes = limit ? sortedTypes.slice(0, limit) : sortedTypes;

  const dataRows = limitedTypes.map(([key, techniqueCounts]) => {
    const row: Array<string | number> = [key.charAt(0).toUpperCase() + key.slice(1)];
    sortedTechniques.forEach((technique) => {
      row.push(techniqueCounts[technique] || 0);
    });
    return row;
  });

  return [headerRow, ...dataRows];
};

const aggregateTotalReferrals = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = 0;
    acc[dateKey] += 1;
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [isNaN(Number(date)) ? date : Number(date), aggregated[date]]);
};

const aggregateTotalDiagnosis = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt, diagnosis }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = 0;

    if (diagnosis !== 'Unspecified') {
      acc[dateKey] += 1;
    }

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [isNaN(Number(date)) ? date : Number(date), aggregated[date]]);
};

const aggregateTotalPlans = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const aggregated = data.reduce((acc: any, { submittedAt, technique, fractions }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = 0;

    if (!acc[dateKey]) acc[dateKey] = { totalPlans: 0, totalFractions: 0 };
    acc[dateKey].totalFractions += fractions;

    if (technique !== 'Unspecified') {
      acc[dateKey].totalPlans += 1;
    }

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      isNaN(Number(date)) ? date : Number(date),
      aggregated[date].totalPlans,
      aggregated[date].totalFractions,
    ]);
};

const aggregateTotalSimulations = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const filteredData = data.filter((item) => isSimAppointment(item.appointment));

  const aggregated = filteredData.reduce((acc: any, { submittedAt }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toISOString().slice(0, 7);

    if (!acc[dateKey]) acc[dateKey] = 0;

    acc[dateKey] += 1;

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((dateKey) => {
      // calling toLocalString() after sorting above to avoid firefox bug
      const formattedDateKey =
        aggregateBy === 'months'
          ? new Date(dateKey + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })
          : dateKey;

      return [isNaN(Number(formattedDateKey)) ? formattedDateKey : Number(formattedDateKey), aggregated[dateKey]];
    });
};

const aggregateTotalTreatments = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const filteredData = data.filter((item) => isTreatmentAppointment(item.appointment));

  const aggregated = filteredData.reduce((acc: any, { submittedAt }: any) => {
    const dateObj = new Date(submittedAt);
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toISOString().slice(0, 7);

    if (!acc[dateKey]) acc[dateKey] = 0;

    acc[dateKey] += 1;

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((dateKey) => {
      // calling toLocalString() after sorting above to avoid firefox bug
      const formattedDateKey =
        aggregateBy === 'months'
          ? new Date(dateKey + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })
          : dateKey;

      return [isNaN(Number(formattedDateKey)) ? formattedDateKey : Number(formattedDateKey), aggregated[dateKey]];
    });
};

const aggregateData = (data: any[], aggregateBy: string, limit?: number, seriesName?: string, divider: number = 1) => {
  const aggregatedData = data.reduce((acc: any, item: any) => {
    const value = item[aggregateBy];
    if (!acc[value]) acc[value] = 0;
    acc[value] += 1;
    return acc;
  }, {});

  const sortedTypes = Object.keys(aggregatedData).sort((a, b) => aggregatedData[b] - aggregatedData[a]);
  const limitedTypes = limit ? sortedTypes.slice(0, limit) : sortedTypes;

  return [
    [aggregateBy.charAt(0).toUpperCase() + aggregateBy.slice(1), seriesName ? seriesName : 'Count'],
    ...limitedTypes.map((type) => [
      ABBREVIATED_TYPES.includes(type) ? type.toUpperCase() : type.charAt(0).toUpperCase() + type.slice(1),
      aggregatedData[type] / divider,
    ]),
  ];
};

const aggregateDataMax = (data: any[], field: string, divider: number = 1) => {
  const aggregatedData = data.reduce((acc: any, item: any) => {
    const value = item[field];
    if (!acc[value]) acc[value] = 0;
    acc[value] += 1;
    return acc;
  }, {});

  const maxValue = Math.max(...(Object.values(aggregatedData) as number[])) / divider;

  return maxValue;
};

const aggregateDataSum = (data: any[], field: string) => {
  const aggregatedData = data.reduce((acc: Record<string, number>, item: any) => {
    const value = item[field];
    if (!acc[value]) acc[value] = 0;
    acc[value] += 1;
    return acc;
  }, {});

  const sum = Object.values(aggregatedData).reduce((acc, item) => acc + item, 0);
  return Number(sum);
};

const aggregatePhysicianFractions = (data: any, limit?: number) => {
  const physicianData = data.reduce((acc: any, { createdBy, fractions, fractionDose }: any) => {
    if (!acc[createdBy]) {
      acc[createdBy] = { totalFractions: 0, totalFractionDose: 0, count: 0 };
    }
    acc[createdBy].totalFractions += fractions;
    acc[createdBy].totalFractionDose += fractionDose;
    acc[createdBy].count += 1;
    return acc;
  }, {});

  const physicianAverages = Object.entries(physicianData).map(([createdBy, data]: [string, any]) => ({
    createdBy,
    avgFractions: data.totalFractions / data.count,
    avgFractionDose: data.totalFractionDose / data.count,
  }));

  const sortedPhysicianAverages = physicianAverages.sort((a, b) => b.avgFractions - a.avgFractions);
  const limitedPhysicianAverages = limit ? sortedPhysicianAverages.slice(0, limit) : sortedPhysicianAverages;

  return [
    [' ', 'Average fractions (#)', `Average dose/fraction (${CurrentAppConfig.Insights?.doseUnit})`],
    ...limitedPhysicianAverages.map(({ createdBy, avgFractions, avgFractionDose }) => [
      createdBy,
      Number(avgFractions.toFixed(1)),
      Number(avgFractionDose.toFixed(1)),
    ]),
  ];
};

const aggregateAverageDailyRecords = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const aggregated = data.reduce((acc: any, { submittedAt, adherence }: any) => {
    const dateObj = new Date(submittedAt);
    if (!isWeekday(dateObj)) return acc;

    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime()
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { count: 0, automationCount: 0, weekdays: new Set() };

    acc[dateKey].count += 1;
    acc[dateKey].weekdays.add(submittedAt);

    // TODO change to automation instead of adherance
    if (adherence === true) {
      acc[dateKey].automationCount += 1;
    }

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      isNaN(Number(date)) ? date : Number(date),
      aggregated[date].count / aggregated[date].weekdays.size,
      aggregated[date].automationCount,
    ]);
};

const aggregateAverageDailyTreatmentsPerLinac = (data: any[], aggregateBy: string | InsightsDateAggregation) => {
  const isWeekday = (date: Date): boolean => ![0, 6].includes(date.getDay()); // Excludes Sundays and Saturdays

  if (data.length === 0) return [];

  const uniqueLinacs = new Set<string>();
  const aggregated = data.reduce((acc: any, { submittedAt, appointment }: any) => {
    const dateObj = new Date(submittedAt);
    if (!appointment || !appointment.location) {
      return acc;
    }

    const linac = appointment?.location.name;
    if (!isLinacLocation(linac) || !isWeekday(dateObj)) return acc;

    // Track unique linacs
    uniqueLinacs.add(linac);

    // Aggregate by the specified period
    const dateKey =
      aggregateBy === 'days'
        ? new Date(dateObj.setHours(12, 0, 0, 0)).getTime() // Aggregate by day
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}` // Aggregate by week
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' }); // Aggregate by month

    if (!acc[dateKey]) acc[dateKey] = { totalTreatments: 0, weekdays: new Set<string>() };

    acc[dateKey].totalTreatments += 1;
    acc[dateKey].weekdays.add(dateObj.toISOString().split('T')[0]); // Unique weekdays

    return acc;
  }, {});

  const totalMachines = uniqueLinacs.size;

  // Calculate the averages and return in the desired format
  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy]) // Ensure sorted keys
    .map((dateKey) => {
      const { totalTreatments, weekdays } = aggregated[dateKey];
      const totalWeekdays = weekdays.size;

      const averageDailyTreatmentsPerLinac =
        totalWeekdays > 0 && totalMachines > 0 ? totalTreatments / totalWeekdays / totalMachines : 0;

      return [isNaN(Number(dateKey)) ? dateKey : Number(dateKey), averageDailyTreatmentsPerLinac];
    });
};

const aggregateAverageDailyTreatmentsPerLinacUnique = (data: any[], limit?: number) => {
  const isWeekday = (date: Date): boolean => ![0, 6].includes(date.getDay());

  const filteredData = data.filter((item) => isTreatmentAppointment(item.appointment));

  if (filteredData.length === 0) return [['Linac', 'Count']];

  // Find the date range
  const uniqueDates = new Set<string>();
  filteredData.forEach(({ submittedAt }) => {
    const date = new Date(submittedAt);
    if (isWeekday(date)) uniqueDates.add(date.toISOString().split('T')[0]);
  });

  const sortedDates = Array.from(uniqueDates).sort();
  const firstDate = new Date(sortedDates[0]);
  const lastDate = new Date(sortedDates[sortedDates.length - 1]);

  const allWeekdays = Array.from({ length: (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24) + 1 })
    .map((_, i) => new Date(firstDate.getTime() + i * (1000 * 60 * 60 * 24)))
    .filter(isWeekday);

  // Aggregate data by linac
  const linacAggregated = data.reduce((acc: any, { submittedAt, appointment }: any) => {
    const dateObj = new Date(submittedAt);
    if (!isWeekday(dateObj) || !appointment || !appointment.location) return acc;

    const linac = appointment?.location.name;
    if (!isLinacLocation(linac)) return acc;

    // Initialize linac entry if not already present
    if (!acc[linac]) acc[linac] = { totalTreatments: 0 };

    acc[linac].totalTreatments += 1;

    return acc;
  }, {});

  // Calculate the average daily treatments for each linac using all weekdays
  const result = Object.entries(linacAggregated).map(([linac, { totalTreatments }]: any) => {
    const totalWeekdays = allWeekdays.length;
    const averageDailyTreatments = totalWeekdays > 0 ? totalTreatments / totalWeekdays : 0;

    return [linac, averageDailyTreatments];
  });

  const linacAggregatedLimited = limit ? result.slice(0, limit) : result;

  return [['Linac', 'Count'], ...linacAggregatedLimited.sort((a, b) => b[1] - a[1])];
};

// METRICS FUNCTIONS
const getMaxAverageDailyTreatments = (data: [string, number][]): number => {
  if (data.length <= 1) return 0; // Handle empty or header-only data

  const aggregatedData = aggregateAverageDailyTreatmentsPerLinacUnique(data);

  return Math.max(...aggregatedData.slice(1).map(([_, count]) => count)); // Ignore header
};

const calcAverageFractions = (data: any[]): number => {
  if (data.length === 0) {
    return 0;
  }
  const totalFractions = data.reduce((sum, item) => sum + item.fractions, 0);

  return totalFractions / data.length;
};

const calcAverageSimToTreat = (data: any[]): number => {
  const patientTimes: { [patientId: string]: { simDate?: Date; treatDate?: Date } } = {};

  data.forEach((record) => {
    if (!record.appointment.patient) {
      return;
    }

    const patientId = record.appointment.patient.id;
    const submittedAt = new Date(record.submittedAt);

    if (!patientTimes[patientId]) {
      patientTimes[patientId] = {};
    }

    if (isSimAppointment(record.appointment)) {
      const currentSimDate = patientTimes[patientId]?.simDate;
      const currentTreatDate = patientTimes[patientId]?.treatDate;

      // Update simDate only if earlier than current simDate and treatDate
      if ((!currentSimDate || submittedAt < currentSimDate) && (!currentTreatDate || submittedAt < currentTreatDate)) {
        patientTimes[patientId].simDate = submittedAt;
      }
    }

    if (isTreatmentAppointment(record.appointment)) {
      const currentTreatDate = patientTimes[patientId]?.treatDate;
      const currentSimDate = patientTimes[patientId]?.simDate;

      // Update treatDate only if earlier than current treatDate and after simDate
      if ((!currentTreatDate || submittedAt < currentTreatDate) && (!currentSimDate || submittedAt > currentSimDate)) {
        patientTimes[patientId].treatDate = submittedAt;
      }
    }
  });

  const differences: number[] = [];

  for (const patientId in patientTimes) {
    const { simDate, treatDate } = patientTimes[patientId];
    if (simDate && treatDate) {
      const differenceInDays = countWeekdays(simDate, treatDate);
      differences.push(parseFloat(differenceInDays.toFixed(2)));
    }
  }

  if (differences.length === 0) return 0;

  const totalDifference = differences.reduce((sum, diff) => sum + diff, 0);
  const averageDifference = totalDifference / differences.length;

  return parseFloat(averageDifference.toFixed(2));
};

const calcAverageSimToTreatPerPhysician = (data: any[], limit?: number): [string, any][] => {
  const groupedTimes: { [createdBy: string]: { [patientId: string]: { simDate?: Date; treatDate?: Date } } } = {};

  data.forEach((record) => {
    if (!record.appointment.patient || !record.createdBy) {
      return;
    }

    const patientId = record.appointment.patient.id;
    const createdBy = record.createdBy;
    const submittedAt = new Date(record.submittedAt);

    if (!groupedTimes[createdBy]) {
      groupedTimes[createdBy] = {};
    }

    if (!groupedTimes[createdBy][patientId]) {
      groupedTimes[createdBy][patientId] = {};
    }

    const patientTimes = groupedTimes[createdBy][patientId];

    // Handle simDate updates
    if (isSimAppointment(record.appointment)) {
      const currentSimDate = patientTimes.simDate;
      const currentTreatDate = patientTimes.treatDate;

      // Only update simDate if it's earlier than currentSimDate and treatDate
      if ((!currentSimDate || submittedAt < currentSimDate) && (!currentTreatDate || submittedAt < currentTreatDate)) {
        patientTimes.simDate = submittedAt;
      }
    }

    // Handle treatDate updates
    if (isTreatmentAppointment(record.appointment)) {
      const currentTreatDate = patientTimes.treatDate;
      const currentSimDate = patientTimes.simDate;

      // Only update treatDate if it's earlier than currentTreatDate and after simDate
      if ((!currentTreatDate || submittedAt < currentTreatDate) && (!currentSimDate || submittedAt > currentSimDate)) {
        patientTimes.treatDate = submittedAt;
      }
    }
  });

  const averages: [string, any][] = [];

  for (const createdBy in groupedTimes) {
    const patientTimes = groupedTimes[createdBy];
    const differences: number[] = [];

    for (const patientId in patientTimes) {
      const { simDate, treatDate } = patientTimes[patientId];
      if (simDate && treatDate) {
        const differenceInDays = countWeekdays(simDate, treatDate);
        differences.push(parseFloat(differenceInDays.toFixed(2)));
      }
    }

    if (differences.length > 0) {
      const totalDifference = differences.reduce((sum, diff) => sum + diff, 0);
      const averageDifference = totalDifference / differences.length;
      averages.push([createdBy, parseFloat(averageDifference.toFixed(2))]);
    }
  }

  const limitedAverages = limit ? averages.slice(0, limit) : averages;
  return [['Physician', 'Value'], ...limitedAverages.sort((a, b) => b[1] - a[1])];
};

const calcComplexTechnique = (data: any[]): number => {
  const matchingRecords = data.filter((record) => COMPLEX_TECHNIQUES.includes(record.technique));
  const percentage = (matchingRecords.length / data.length) * 100;

  return isNaN(percentage) ? 0 : percentage;
};

const calcAdherencePercentage = (data: any[]): number => {
  const adherentRecords = data.filter((record) => record.adherence === true);
  const percentage = (adherentRecords.length / data.length) * 100;

  return percentage;
};

const calcTotalReferrals = (data: any[]): number => {
  return data.length;
};

const calcTotalDiagnosis = (data: any[]): number => {
  const diagnosisRecords = data.filter((record) => record.diagnosis !== 'Unspecified');
  return diagnosisRecords.length;
};

const calcTotalPlans = (data: any[]): number => {
  const techniqueRecords = data.filter((record) => record.technique !== 'Unspecified');
  return techniqueRecords.length;
};

const calcTotalSimAppointments = (data: any[]): number => {
  const filteredData = data.filter((item) => isSimAppointment(item.appointment));
  return filteredData.length;
};

const calcTotalTreatmentAppointments = (data: any[]): number => {
  const filteredData = data.filter((item) => isTreatmentAppointment(item.appointment));
  return filteredData.length;
};

const calcAverageDailyPlans = (data: any[]): number => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const weekdays = new Set();
  let totalRecords = 0;

  data.forEach(({ submittedAt }: any) => {
    const dateObj = new Date(submittedAt);
    if (isWeekday(dateObj)) {
      totalRecords += 1;
      weekdays.add(submittedAt);
    }
  });

  const totalWeekdays = weekdays.size;
  return totalWeekdays > 0 ? totalRecords / totalWeekdays : 0;
};

const calcAverageDailyTreatmentsPerLinac = (data: any[]) => {
  const isWeekday = (date: Date): boolean => ![0, 6].includes(date.getDay()); // Excludes Sundays and Saturdays

  const filteredData = data.filter((item) => isTreatmentAppointment(item.appointment));

  if (filteredData.length === 0) return 0;

  const uniqueDates = new Set<string>();
  const uniqueLinacs = new Set<string>();
  const totalTreatments = calcTotalTreatmentAppointments(data);

  filteredData.forEach(({ submittedAt, appointment }) => {
    const date = new Date(submittedAt);
    if (appointment && appointment.location) {
      const linac = appointment?.location.name;
      if (!isLinacLocation(linac)) return;

      if (isWeekday(date)) {
        uniqueDates.add(date.toISOString().split('T')[0]);
        uniqueLinacs.add(linac);
      }
    }
  });

  const sortedDates = Array.from(uniqueDates).sort();
  const firstDate = new Date(sortedDates[0]);
  const lastDate = new Date(sortedDates[sortedDates.length - 1]);

  const totalWeekdays = Array.from({ length: (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24) + 1 })
    .map((_, i) => new Date(firstDate.getTime() + i * (1000 * 60 * 60 * 24)))
    .filter(isWeekday).length;

  const totalMachines = uniqueLinacs.size;

  return totalWeekdays > 0 && totalMachines > 0 ? totalTreatments / totalWeekdays / totalMachines : 0;
};

export const DataFunctions = {
  aggregateAverageFractions,
  aggregateMonth,
  calcAverageFractions,
  calcComplexTechnique,
  aggregateTechniquePercentage,
  aggregateAdherencePercentage,
  calcAdherencePercentage,
  aggregateTotalReferrals,
  calcTotalReferrals,
  aggregateAverageDailyTreatmentsPerLinac,
  aggregateAverageDailyTreatmentsPerLinacUnique,
  aggregateAverageSimToTreat,
  calcAverageSimToTreat,
  aggregateAverageDailyRecords,
  calcAverageDailyPlans,
  aggregateTotalDiagnosis,
  calcTotalDiagnosis,
  aggregateTotalPlans,
  calcTotalPlans,
  aggregatePhysicianFractions,
  aggregateData,
  aggregateDataMax,
  aggregateStackedBarData,
  aggregateAdherenceByField,
  aggregateTechniqueByField,
  calcAverageDailyTreatmentsPerLinac,
  aggregateAdherenceValues,
  calcTotalSimAppointments,
  calcTotalTreatmentAppointments,
  aggregateTotalSimulations,
  aggregateTotalTreatments,
  aggregateDataSum,
  getMaxAverageDailyTreatments,
  calcAverageSimToTreatPerPhysician,
};
