// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useFormikContext, Field, getIn } from 'formik';
import { ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import { IntakePageProps, FormikValues } from './Interface';
import {
  LYMPHOMA_STAGE_OPTIONS,
  A_B_SYMPTOMS_OPTIONS,
  EXTRANODAL_OPTIONS,
  BULK_DISEASE_OPTIONS,
  VALUE_REQUIRED,
  absymptomsTooltip,
  extranodalTooltip,
  bulkDiseaseTooltip,
} from './helpers';
import { tooltipIconWrapper } from '../../helpers';
import { Divider } from '@mui/material';
import { SelectOptionType } from 'shared-components/components/FormFields';

const ROPatientDiagnosisIntakeLymphomaPage = ({ updateIntakeHandler }: IntakePageProps): JSX.Element => {
  const { values, touched } = useFormikContext<FormikValues>();

  if (values.intake?.lymphoma) {
    const { stage, aOrBSymptoms, extranodal, bulkDisease } = values.intake.lymphoma;
    return (
      <>
        <Field
          name="stage"
          component={ROAutocomplete}
          id="stage"
          fieldlabel="Stage"
          options={LYMPHOMA_STAGE_OPTIONS}
          value={stage}
          required
          inputProps={{
            error: getIn(touched, 'stage') && !stage,
            helperText: VALUE_REQUIRED,
          }}
          onChange={(selectedOption: SelectOptionType): void => {
            updateIntakeHandler({ stage: selectedOption ? selectedOption.value : '' });
          }}
        />
        <Divider sx={{ margin: '32px 0' }} />
        <Field
          name="aOrBSymptoms"
          component={ROToggleButtons}
          id="aorbSymptoms"
          fieldlabel="A or B Symptoms"
          options={A_B_SYMPTOMS_OPTIONS}
          value={aOrBSymptoms}
          required
          error={getIn(touched, 'aOrBSymptoms') && !aOrBSymptoms}
          handleChange={(value: string): void => {
            updateIntakeHandler({ aOrBSymptoms: value });
          }}
          tooltip={tooltipIconWrapper(absymptomsTooltip)}
        />
        <Field
          name="extranodal"
          component={ROToggleButtons}
          id="extranodal"
          fieldlabel="Extranodal"
          options={EXTRANODAL_OPTIONS}
          value={extranodal}
          required
          error={getIn(touched, 'extranodal') && !extranodal}
          handleChange={(value: string): void => {
            updateIntakeHandler({ extranodal: value });
          }}
          tooltip={tooltipIconWrapper(extranodalTooltip)}
        />
        <Field
          name="bulkDisease"
          component={ROToggleButtons}
          id="bulkDisease"
          fieldlabel="Bulk Disease"
          options={BULK_DISEASE_OPTIONS}
          value={bulkDisease}
          required
          error={getIn(touched, 'bulkDisease') && !bulkDisease}
          handleChange={(value: string): void => {
            updateIntakeHandler({ bulkDisease: value });
          }}
          tooltip={tooltipIconWrapper(bulkDiseaseTooltip)}
        />
      </>
    );
  }
  return <> </>;
};

export default ROPatientDiagnosisIntakeLymphomaPage;
