import { findParentById } from '../../Utils/utils';
import { InsightsDateAggregation } from 'oncology_portal/src/enums';
import Highcharts from 'highcharts';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const CHART_SIZE_OFFSET = 580;
const chartSpacingConfig = [30, 20, 10, 20];

const syncConfig = {
  visibility: true,
  highlight: true,
  extremes: true,
};

const creditsConfig = {
  enabled: false,
};

const exportingConfig = {
  buttons: {
    contextButton: {
      menuItems: ['downloadCSV', 'downloadXLS'],
      y: -20,
      x: 5,
      symbol: 'download',
      symbolSize: 24,
    },
  },
};

const subExportingConfig = {
  buttons: {
    contextButton: {
      menuItems: ['downloadCSV', 'downloadXLS'],
      x: 110,
      y: -7,
      symbol: 'download',
      symbolSize: 24,
    },
  },
};

const handlePointClick = (point: Highcharts.Point, onPointClick: (seriesName: string, seriesIndex: number) => void) => {
  onPointClick(point.name, point.index);
};

// hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
const setHeight = (chart: Highcharts.Chart) => {
  const currentElement = findParentById(chart.container, 'ROContainer');
  const parentSize = currentElement?.clientHeight;

  if (parentSize) {
    chart.setSize(undefined, Number((parentSize - CHART_SIZE_OFFSET).toFixed(0)));
  }
};

const selectPoint = (chart: Highcharts.Chart, selectedPoint: string, selectedIndex: number) => {
  chart.series.forEach(function (series) {
    if (series.visible) {
      const point = series.data[selectedIndex];

      if (point && selectedPoint) {
        if (point.graphic?.element) {
          point.graphic.element.style.fillOpacity = '1';
        }
      }
    }
  });
};

interface TopChartParams {
  onPointClick: (seriesName: string) => void;
  selectedPoint: string;
  selectedIndex: number;
  seeMoreUpdated: boolean;
  leftSeriesTitle: string;
  rightSeriesTitle: string;
  leftSeriesFields: string[];
  rightSeriesFields: string[];
  extraSeriesFields?: string[];
  dateRange: string;
  endDate: Date;
  aggregation: InsightsDateAggregation;
  tooltipSuffix?: string;
  rightSeriesEnabled?: boolean;
  opacity?: number;
  stacking?: string;
  seriesColorMapping?: any;
  tooltipPrecision?: number;
}

interface BottomChartParams {
  title: string;
  connector: string;
  cell: string;
  filterOverride: string;
  seeMore: boolean;
  precision?: number;
  dataLabelTitle?: string;
  dataLabelTitleWidth?: number;
  yAxisMax?: number;
  theme: any;
  toggleSeeMore: (cell: string) => void;
  dataLength?: number;
  seriesFields?: string[];
  dataTotal?: number;
  numLabels?: number;
}

const seeMoreButtonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  padding: '8px',
  bottom: '45px',
  left: '20px',
  cursor: 'pointer',
  borderRadius: '4px',
  border: '1px solid rgba(8, 30, 67, 0.50)',
  width: '100px',
  fontSize: '14px',
  fontWeight: '500',
  zIndex: '1',
};

const dateRangeMap: Record<string, number> = {
  'Past 30 days': 30,
  'Past 90 days': 90,
  'Past 6 months': 180,
  'Past 1 year': 365,
  'All time': 0,
};

const loadSeeMoreButton = (
  chart: Highcharts.Chart,
  toggleSeeMore: (cell: string) => void,
  seeMore: boolean,
  cell: string,
) => {
  const container = chart.container;

  const seeMoreButton = document.createElement('div');

  Object.assign(seeMoreButton.style, seeMoreButtonStyle);

  seeMoreButton.addEventListener('mouseenter', () => {
    seeMoreButton.style.backgroundColor = 'rgba(8, 30, 67, 0.05)';
  });

  seeMoreButton.addEventListener('mouseleave', () => {
    seeMoreButton.style.backgroundColor = 'transparent';
  });

  seeMoreButton.textContent = seeMore ? 'SEE LESS' : 'SEE MORE';
  seeMoreButton.onclick = () => {
    toggleChartHeight(container, seeMoreButton);
    toggleSeeMore(cell);
  };
  if (seeMore) {
    container.classList.add('expanded');
    container.parentElement?.parentElement?.classList.add('expanded');
    container.parentElement?.parentElement?.parentElement?.classList.add('expanded');
  }
  container.appendChild(seeMoreButton);

  const toggleChartHeight = (container: any, button: any) => {
    container.classList.toggle('expanded');
    container.parentElement?.parentElement?.classList.toggle('expanded');
    container.parentElement.parentElement.parentElement.classList.toggle('expanded');

    if (container.classList.contains('expanded')) {
      button.textContent = 'SEE LESS';
    } else {
      button.textContent = 'SEE MORE';
    }
  };
};

const subDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const columnSeriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);
  return trimmedFields.map((field, index) => ({
    seriesId: 'series-' + index,
    data: ['submittedAt', field],
  }));
};

const seriesMapping = (
  leftSeriesFields: string[],
  aggregation: InsightsDateAggregation,
  seriesColorMapping: any,
  opacity: number = 1,
) => {
  const trimmedFields = leftSeriesFields.slice(1);

  return trimmedFields.map((field, index) => ({
    id: 'series-' + index,
    type: 'column',
    name: field,
    color: seriesColorMapping[field as keyof typeof seriesColorMapping] || 'rgba(158, 158, 158)',
    fillOpacity: opacity,
    ...(aggregation === InsightsDateAggregation.DAYS && {
      pointPlacement: 'on',
      pointRange: 24 * 60 * 60 * 1000,
    }),
  })) as Highcharts.SeriesOptionsType[];
};

const xAxisConfig = (
  aggregation: InsightsDateAggregation,
  dateRange: string,
  endDate: Date,
): Highcharts.XAxisOptions => ({
  type: aggregation === InsightsDateAggregation.DAYS ? 'datetime' : 'category',
  ordinal: false,
  dateTimeLabelFormats: {
    day: CurrentAppConfig.Insights?.chartDateFormat === 'MM/DD/YYYY' ? '%m/%d' : '%d/%m',
  },
  min:
    aggregation === InsightsDateAggregation.DAYS ? subDays(endDate!, dateRangeMap[dateRange!] + 1.4).getTime() : null,
  max: aggregation === InsightsDateAggregation.DAYS ? endDate!.getTime() + 24 * 60 * 60 * 1000 : null,
});

const columnTooltipConfig = (
  aggregation: InsightsDateAggregation,
  suffix: string,
  precision: number = 0,
): Highcharts.TooltipOptions => ({
  formatter: function () {
    let tooltip = '';

    if (aggregation === InsightsDateAggregation.DAYS) {
      tooltip += `${Highcharts.dateFormat(
        CurrentAppConfig.Insights?.chartDateFormat === 'MM/DD/YYYY' ? '%m/%d/%Y' : '%d/%m/%Y',
        Number(this.x),
      )}<br/>`;
    } else if (this.points && this.points[0]?.point) {
      tooltip += `${this.points[0].point.name}<br/><br/>`;
    }

    this.points!.forEach(function (point) {
      if (!point.series.name.includes('VS')) {
        tooltip += `${point.series.name}: ${point.y?.toFixed(precision)} ${suffix}<br/>`;
      }
    });

    return tooltip;
  },
  shared: true,
});

export {
  setHeight,
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  subExportingConfig,
  loadSeeMoreButton,
  handlePointClick,
  selectPoint,
  TopChartParams,
  BottomChartParams,
  CHART_SIZE_OFFSET,
  seeMoreButtonStyle,
  dateRangeMap,
  subDays,
  columnSeriesMapping,
  seriesMapping,
  xAxisConfig,
  columnTooltipConfig,
};
