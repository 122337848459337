import React, { useState } from 'react';
import { VARIATION_MESSAGE } from '../Constants';
import { ROTextField } from 'shared-components/components/FormFields';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

interface Site {
  ctv: string;
  ptv: string;
}

interface TvFieldProps {
  initialValue: string;
  templateValue: string;
  index: number;
  prefix: 'G' | 'C' | 'P';
  suffix: string;
  cpotTriggered: boolean;
  updateField: (value: string) => void;
}

const TvField = ({
  initialValue,
  templateValue,
  updateField,
  index,
  prefix,
  suffix,
  cpotTriggered,
}: TvFieldProps): JSX.Element => {
  const [tv, setTv] = useState(initialValue);
  // Only warn for PTV and CTV, GTV is not marked for variation (See Variation Rules model in backend)
  const warning = !cpotTriggered && prefix !== 'G' && !!templateValue && tv !== templateValue;
  const label =
    suffix && CurrentAppConfig.VolumingPage.TargetValueDoseSuffixEnabled ? `${prefix}TV_${suffix}` : `${prefix}TV`;
  return (
    <ROTextField
      id={`voluming${prefix}tv-${index}`}
      fieldlabel={label}
      helperText={VARIATION_MESSAGE}
      warning={warning}
      value={tv}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setTv(event.target.value);
      }}
      onBlur={(): void => {
        updateField(tv);
      }}
      multiline
      maxRows={4}
      maxLength={600}
    />
  );
};

export default TvField;
