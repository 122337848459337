import { gql } from '@apollo/client';

export const INSIGHTS_USER_QUERY = gql`
  {
    user {
      id
      isInsightsUser
    }
  }
`;

export const GET_CAREPLANS_QUERY = gql`
  query careplansQuery(
    $page: Int
    $rowsPerPage: Int
    $search: String
    $status: [String]
    $priority: String
    $ordering: String
    $careplanChanged: [String]
    $onlyForRequestingPractitioner: Boolean
    $submittedBy: [String]
    $onOrBeforeDate: String
    $onOrAfterDate: String
    $insightsRequest: Boolean
  ) {
    careplans(
      page: $page
      rowsPerPage: $rowsPerPage
      search: $search
      status: $status
      priority: $priority
      ordering: $ordering
      careplanChanged: $careplanChanged
      onlyForRequestingPractitioner: $onlyForRequestingPractitioner
      submittedBy: $submittedBy
      onOrBeforeDate: $onOrBeforeDate
      onOrAfterDate: $onOrAfterDate
      insightsRequest: $insightsRequest
    ) {
      id
      careplanStatus
      diagnosisCode
      priority
      diagnosis {
        diagnosisCode {
          diagnosisDescription
          tumourStream {
            name
          }
        }
      }
      submittedAt
      createdBy
      treatingDepartment
      patient {
        id
      }
      sitegroupSet {
        siteSet {
          treatmentSite {
            treatmentSite
          }
          sitevaluesSet {
            field {
              name
            }
            value
          }
        }
      }
    }
  }
`;

export const DOCTOR_APPOINTMENT_QUERY = gql`
  query doctorAppointmentByDateWithPractioners(
    $startDate: DateTime
    $endDate: DateTime
    $appointmentTypes: [String]
    $excludeCancelled: Boolean
    $allPractitioners: Boolean
  ) {
    doctorAppointmentsByDateWithPractitioners(
      startDate: $startDate
      endDate: $endDate
      appointmentTypes: $appointmentTypes
      excludeCancelled: $excludeCancelled
      allPractitioners: $allPractitioners
    ) {
      id
      appointment {
        startTime
        patient {
          id
        }
        appointmentType
        location {
          name
        }
        description
      }
      relatedPractitioners {
        practitioner {
          firstName
          lastName
        }
        relationshipType
      }
    }
  }
`;
