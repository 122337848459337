import React from 'react';
import { CardTitle, CardBody, CardBodyGroup, CardBodyBold } from './styles';
import CONSTANTS from './constants';

interface AlternateContact {
  firstName: string;
  lastName: string;
  mobilePhoneNumber: string;
  homePhoneNumber: string;
  relationship: string;
  id: string;
}

interface Props {
  name: string;
  patientDetails: {
    healthMedicareNumber: string;
    healthMedicareIrn: string;
    medicareExpiryRawMonth: string;
    medicareExpiryRawYear: string;
    emergencyContact: AlternateContact;
    nextOfKinContact: AlternateContact;
  };
}

const selectBestContact = (emergencyContact: any, nextOfKinContact: any) => {
  // If emergency contact has a first name, assume emergency contact is the best one to use
  if (emergencyContact?.firstName) {
    return emergencyContact;
  }
  return nextOfKinContact;
};

const EmergencyContactCard = ({ name, patientDetails }: Props): JSX.Element => {
  const { emergencyContact, nextOfKinContact } = patientDetails;

  const alternateContact = selectBestContact(emergencyContact, nextOfKinContact);

  const contactNumber = alternateContact?.mobilePhoneNumber || alternateContact?.homePhoneNumber || '-';
  const hasEmergencyName = alternateContact?.firstName || alternateContact?.lastName;
  const { EMG_CONTACT, RELATIONSHIP, CONTACT } = CONSTANTS;

  return (
    <div data-testid="emergencyContactCard">
      <CardTitle>{name}</CardTitle>
      <CardBody>
        <CardBodyGroup data-testid="emgContactName">
          {EMG_CONTACT}
          <CardBodyBold>
            {hasEmergencyName ? `${alternateContact.firstName} ${alternateContact.lastName}` : '-'}
          </CardBodyBold>
        </CardBodyGroup>
        <CardBodyGroup data-testid="emgContactRelationship">
          {RELATIONSHIP}
          <CardBodyBold>{alternateContact?.relationship || '-'}</CardBodyBold>
        </CardBodyGroup>
        <CardBodyGroup data-testid="emgContactPhone">
          {CONTACT}
          <CardBodyBold>{contactNumber}</CardBodyBold>
        </CardBodyGroup>
      </CardBody>
    </div>
  );
};

export default EmergencyContactCard;
