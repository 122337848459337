import { gql } from '@apollo/client';

export const UPDATE_TREATMENTINFO = gql`
  mutation updateCareplan(
    $id: ID
    $readyForCareDate: String
    $intent: String
    $preferredTreatmentStartDate: String
    $startDateAdditionalInformation: String
    $treatingDepartment: String
    $previousRadioTreatment: String
    $patientStatus: String
    $priority: String
    $pregnancy: String
    $delayCategory: String
    $isDelayCategoryManual: Boolean
    $cied: String
    $ciedPatientDependancy: String
    $ciedLinacRequired: String
    $inPatientAt: String
    $ciedProtocolRequired: String
    $preTreatmentCiedCheckRequired: String
    $previousCompositePlanRequired: String
    $previousTreatmentAt: String
    $previousTreatmentAdditionalInformation: String
    $previousTreatmentRetreatment: String
    $previousTreatmentRetreatmentType: String
    $referralToGcCardiologist: String
    $otherCiedInstructions: String
    $cardioonc: String
    $endoscope: String
    $chemotherapy: String
    $chemoRegime: String
    $chemoWhere: String
    $customChemoWhere: String
    $chemoSequencing: String
    $chemoStartDate: String
    $chemoEndDate: String
    $hormones: String
    $hormonesRegime: String
    $hormonesStartDate: String
    $hormonesEndDate: String
    $previousMdt: String
    $brachytherapy: String
    $brachyWhere: String
    $brachySequencing: String
    $brachyDose: String
    $brachyStartDate: String
    $brachyEndDate: String
    $performanceScaleType: String
    $performanceScaleRating: String
    $chiefComplaint: String
    $impressionPlan: String
    $consentStatus: String
    $pharmacyPrescription: String
    $pharmacyPrescriptionOther: String
  ) {
    updateCareplan(
      id: $id
      readyForCareDate: $readyForCareDate
      intent: $intent
      preferredTreatmentStartDate: $preferredTreatmentStartDate
      startDateAdditionalInformation: $startDateAdditionalInformation
      treatingDepartment: $treatingDepartment
      previousRadioTreatment: $previousRadioTreatment
      patientStatus: $patientStatus
      pregnancy: $pregnancy
      priority: $priority
      cied: $cied
      ciedPatientDependancy: $ciedPatientDependancy
      ciedLinacRequired: $ciedLinacRequired
      delayCategory: $delayCategory
      isDelayCategoryManual: $isDelayCategoryManual
      inPatientAt: $inPatientAt
      ciedProtocolRequired: $ciedProtocolRequired
      preTreatmentCiedCheckRequired: $preTreatmentCiedCheckRequired
      previousTreatmentAdditionalInformation: $previousTreatmentAdditionalInformation
      previousCompositePlanRequired: $previousCompositePlanRequired
      previousTreatmentAt: $previousTreatmentAt
      previousTreatmentRetreatment: $previousTreatmentRetreatment
      previousTreatmentRetreatmentType: $previousTreatmentRetreatmentType
      referralToGcCardiologist: $referralToGcCardiologist
      otherCiedInstructions: $otherCiedInstructions
      cardioonc: $cardioonc
      endoscope: $endoscope
      chemotherapy: $chemotherapy
      chemoRegime: $chemoRegime
      chemoWhere: $chemoWhere
      customChemoWhere: $customChemoWhere
      chemoSequencing: $chemoSequencing
      chemoStartDate: $chemoStartDate
      chemoEndDate: $chemoEndDate
      hormones: $hormones
      hormonesRegime: $hormonesRegime
      hormonesStartDate: $hormonesStartDate
      hormonesEndDate: $hormonesEndDate
      previousMdt: $previousMdt
      brachytherapy: $brachytherapy
      brachyWhere: $brachyWhere
      brachySequencing: $brachySequencing
      brachyDose: $brachyDose
      brachyStartDate: $brachyStartDate
      brachyEndDate: $brachyEndDate
      performanceScaleType: $performanceScaleType
      performanceScaleRating: $performanceScaleRating
      chiefComplaint: $chiefComplaint
      impressionPlan: $impressionPlan
      consentStatus: $consentStatus
      pharmacyPrescription: $pharmacyPrescription
      pharmacyPrescriptionOther: $pharmacyPrescriptionOther
    ) {
      careplan {
        id
        readyForCareDate
        intent
        preferredTreatmentStartDate
        startDateAdditionalInformation
        treatingDepartment
        previousRadioTreatment
        pregnancy
        patientStatus
        priority
        cied
        ciedPatientDependancy
        ciedLinacRequired
        inPatientAt

        ciedProtocolRequired
        preTreatmentCiedCheckRequired
        previousCompositePlanRequired
        previousTreatmentAt
        previousTreatmentAdditionalInformation
        previousTreatmentRetreatment
        previousTreatmentRetreatmentType
        referralToGcCardiologist
        otherCiedInstructions
        cardioonc
        endoscope
        chemotherapy
        chemoRegime
        chemoWhere
        customChemoWhere
        chemoSequencing
        chemoStartDate
        chemoEndDate
        hormones
        hormonesRegime
        hormonesStartDate
        hormonesEndDate
        previousMdt
        brachytherapy
        brachyWhere
        brachySequencing
        brachyDose
        brachyStartDate
        brachyEndDate
        performanceScaleType
        performanceScaleRating
        chiefComplaint
        impressionPlan

        consentStatus
        pharmacyPrescription
        pharmacyPrescriptionOther
      }
    }
  }
`;

export const LOAD_TREATMENTINFO = gql`
  query careplan($id: ID!) {
    careplan(id: $id) {
      id
      readyForCareDate
      intent
      preferredTreatmentStartDate
      startDateAdditionalInformation
      treatingDepartment
      previousRadioTreatment
      patientStatus
      priority
      pregnancy
      delayCategory
      isDelayCategoryManual
      cied
      ciedPatientDependancy
      ciedLinacRequired
      inPatientAt
      ciedProtocolRequired
      preTreatmentCiedCheckRequired
      previousCompositePlanRequired
      previousTreatmentAt
      previousMdt
      previousTreatmentAdditionalInformation
      previousTreatmentRetreatment
      previousTreatmentRetreatmentType
      referralToGcCardiologist
      otherCiedInstructions
      cardioonc
      endoscope
      chemotherapy
      chemoRegime
      chemoWhere
      customChemoWhere
      chemoSequencing
      chemoStartDate
      chemoEndDate
      hormones
      hormonesRegime
      hormonesStartDate
      hormonesEndDate
      brachytherapy
      brachyWhere
      brachySequencing
      brachyDose
      brachyStartDate
      brachyEndDate
      performanceScaleType
      performanceScaleRating
      chiefComplaint
      impressionPlan
      consentStatus
      pharmacyPrescription
      pharmacyPrescriptionOther
    }
  }
`;

export const LOAD_TREATMENT_LIST_DATA = gql`
  query carepathDataReferenceList($listCategory: [String!]!, $patientId: ID) {
    carepathDataReferenceList(listCategory: $listCategory, patientId: $patientId) {
      id
      data {
        id
        option
      }
      listWeight
      category {
        id
        category
      }
    }
  }
`;

export const GET_PATIENT_PROFILE = gql`
  query patient($patientId: ID!) {
    patient(id: $patientId) {
      id
      fullName
      gender
      ida
      dob
      healthMedicareNumber
      secondaryPhone
      patientState
      primaryPhone
      address {
        id
        formattedAddress
      }
    }
  }
`;
