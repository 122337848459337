import React from 'react';
import { useLocation } from 'react-router-dom';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { useTheme } from '@mui/material';

const OutageBanner = (): JSX.Element => {
  const location = useLocation();
  const currentDate = new Date();
  const theme = useTheme();

  const currentUrl = location.pathname;

  const cutoffDate = new Date(2024, 2, 17);
  const showDate = new Date(2024, 2, 11);
  const isAfterCutOverDate = currentDate > cutoffDate;
  const isAfterShowBannerDate = currentDate > showDate;
  const showBanner =
    CurrentAppConfig?.Outage?.showBanner &&
    Boolean(currentUrl === '/radiation' || currentUrl === '/search') &&
    !isAfterCutOverDate &&
    isAfterShowBannerDate;

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: theme.palette.grey[600],
        height: '48px',
        display: showBanner ? 'block' : 'none',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '16px',
          lineHeight: '24px',
          color: 'white',
          fontWeight: '500',
          fontStyle: ' normal',
          height: '48px',
        }}>
        {CurrentAppConfig?.Outage.message}
        <a
          href={CurrentAppConfig?.Outage.link}
          style={{
            color: 'white',
            textDecoration: 'underline',
            marginLeft: '10px',
          }}
          target="_blank">
          Learn more{' '}
        </a>
      </div>
    </div>
  );
};

export default OutageBanner;
