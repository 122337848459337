import { CareplanFilter, CareplanStatusFilter, RoDashboardTabs } from 'op-enums';
import { BaseConfigInterface } from './types';
import { BOOLEAN_OPTIONS, BOOLEAN_TBC_OPTIONS } from '../TreatmentInfoPage/Interface';

const anz_conf: BaseConfigInterface = {
  SpecialPhysicsConsultVisible: false,
  RequestTemplateChangeActive: false,
  ErrorPopup: {
    Generic:
      "We encountered an error. Please refresh the page. If the issue persists, please contact support: <a href='mailto:support-anz@lumonus.com'>support-anz@lumonus.com</a>",
    Practitioner:
      "We encountered an error. Please refresh the page. If the issue persists, please contact support: <a href='mailto:support-anz@lumonus.com'>support-anz@lumonus.com</a>",
    Patient: 'We encountered an error. Please refresh the page. If the issue persists, please contact your clinic.',
  },
  ReferringPhysicians: {
    oncologist: 'Responsible Physician',
    generalPractitioner: 'Primary Physician (GP)',
    referringSurgeon: 'Referring Physician 1',
    referringSurgeon2: 'Referring Physician 2',
    referringSurgeon3: 'Referring Physician 3',
  },
  DefaultValue: {
    cied: '',
    patientStatus: 'outPatient',
    previousRadioTreatment: '',
    treatmentRORequired: '',
    tumourName: 'tumour ',
  },
  Clinex: {
    betaTesting: true,
  },
  Intake: {
    disabled: false,
    isProstateDisabled: false,
    breastMandatoryFields: [],
    lungMandatoryFields: [],
    headNeckMandatoryFields: [],
    lymphomaMandatoryFields: [],
    breastFormVersion: 2,
  },
  PatientSummary: {
    isVisible: true,
    labsEnabled: true,
    RODocumentUpload: false,
    documentFilterField: 'description',
    hideInsuranceTab: true,
  },
  OutcomesPage: {
    isVisible: false,
    DateFormat: 'DD-MMM-YYYY',
    DateTimeFormat: 'DD MMM YYYY h:mma',
  },
  OTRPage: {
    isVisible: false,
    DateFormat: 'DD-MMM-YYYY',
    DateTimeFormat: 'DD MMM YYYY h:mma',
  },
  DisplayButton: {
    displaySimButton: true,
  },
  DisplayModal: {
    submission: false,
  },
  TreatmentInfoPage: {
    mandatoryFields: ['intent', 'treatingDepartment', 'readyForCareDate', 'cied', 'previousRadioTreatment'],
    ReadyForCareDate: true,
    AdditionalBookingInfo: true,
    DelayCategory: true,
    MDT: false,
    PreviousTreatmentRetreatment: false,
    PreviousTreatmentRetreatmentType: false,
    AuthorizedICDTreatingDepartments: [],
    Hormones: false,
    Brachytherapy: false,
    ccip: false,
    TreatmentStartDate: false,
    ConsentStatus: true,
    PharmacyPrescription: true,
  },
  SimulationPage: {
    ContrastRequiredChildFields: true,
    SpacerGelWarning: false,
    imagingText: 'Add imaging request',
    imagingCountTitle: 'Imaging',
    disableExtentDefined: true,
    disablePositionSetup: true,
    immobilisationLabel: 'Immobilisation',
  },
  PrescriptionPage: {
    MicrodosimetryTld: false,
    WeeklyPhysicsCheck: false,
    BasicDoseCalculation: false,
  },
  SubmissionPage: {
    PlanAimSubmissionType: 'Plan Aim',
    CPOTEnabled: false,
    PlanAimHidden: true,
  },
  VolumingPage: {
    UsePrioritizedOARs: true,
    TargetValueDoseSuffixEnabled: false,
  },
  RadiationDashboard: {
    dateTimeFormat: 'DD/MM/YYYY hh:mm A',
    active: RoDashboardTabs.APPOINTMENTS,
    careplanDashboard: {
      careplanStatusFilters: [
        { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
        { id: CareplanStatusFilter.SIMULATION, name: CareplanStatusFilter.SIMULATION, checked: true },
        { id: CareplanStatusFilter.PLAN_AIM, name: CareplanStatusFilter.PLAN_AIM, checked: true },
        { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
        { id: CareplanStatusFilter.CPOT, name: CareplanStatusFilter.CPOT, checked: true },
      ],
      careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
      isPatientSummaryLinkEnabled: true,
    },
    showFilters: false,
  },
  QuestionLabel: {
    CIED: 'Cardiac Implantable Electronic Device (CIED)',
    RoRequired: 'RO Required',
    GoldSeeds: 'Gold Seeds',
    phase: 'Phase',
  },
  QuestionOptions: {
    CIED: BOOLEAN_TBC_OPTIONS,
    previousRadioTreatment: BOOLEAN_OPTIONS,
  },
  QuestionValidationChildren: {
    CIED: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'],
    Staging: {
      Primary: {
        RO: ['laterality', 'diagnosisDate'],
        MO: ['laterality'],
      },
      Metastasis: {
        RO: ['laterality', 'diagnosisDate'],
        MO: [],
      },
    },
  },
  ResetMutationVariableDefaults: {
    CIED: {
      ciedProtocolRequired: '',
      preTreatmentCiedCheckRequired: '',
      referralToGcCardiologist: '',
      otherCiedInstructions: '',
    },
    CiedProtocolRequired: {
      preTreatmentCiedCheckRequired: '',
      referralToGcCardiologist: '',
      otherCiedInstructions: '',
    },
  },
  GetMutationVariableDefaults: {
    CIED: {
      ciedProtocolRequired: 'yes',
      preTreatmentCiedCheckRequired: 'yes',
      referralToGcCardiologist: 'no',
      otherCiedInstructions: '',
    },
    CiedProtocolRequired: {
      preTreatmentCiedCheckRequired: 'yes',
      referralToGcCardiologist: 'no',
      otherCiedInstructions: '',
    },
  },
  DoseSiteSummary: {
    enabled: true,
    DateFormat: 'DD/MM/YYYY',
  },
  SubmitDiagnosisOnlyAndExit: false,
  CardioReferralField: false,
  Tooltips: {
    hasPriority: false,
    hasClinicalPathologicalStaging: true,
  },
  UseDocumentTimezone: true,
  DefaultTimezone: 'Pacific/Auckland',
  DateFormat: 'DD MM YYYY',
  CareplanVoidTimeRestriction: 168,
  CareplanVoidDescription: 'Documentation will be voided in your OIS.',
  EmailMaxLength: 40,
  Outage: {
    link: '',
    showBanner: false,
    message: '',
  },
  Notes: {
    EnableSubmit: true,
    DateTimeFormat: 'DD/MM/YYYY hh:mm A',
  },
  DefaultLocale: 'en-au',
  SupportEmail: 'support-anz@lumonus.com',
  ShowResetDemoDataMenuLink: false,
  Insights: {
    enabled: true,
    mockData: false,
    defaultDateFilter: 'Past 30 days',
    doseUnit: 'Gy',
    dataSource: 'MOSAIQ',
    chartDateFormat: 'DD/MM/YYYY',
    linacs: ['Tahi', 'Linac_1', 'Unity1'],
    defaultDepartment: 'SGCCC',
    Dashboards: {
      careplanAdherence: false,
      techniques: true,
      averageFractions: true,
      totalReferrals: false,
      totalDiagnosis: true,
      totalPlans: false,
      linacs: true,
      simToTreatment: true,
      averageDailyPlans: false,
      totalConsultations: false,
      totalSimulations: true,
      totalTreatments: true,
    },
  },
  MenuItems: {
    manageProtocols: false,
  },
};

export default anz_conf;
