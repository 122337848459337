import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { DATATYPE } from 'shared-components/components/UIFormComponents/SimpleTable';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { Stack } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

interface StyledRowProps {
  active: boolean;
}

interface TableHeaderProps {
  title: string;
  key: string;
  sort?: boolean;
  type: string;
}

interface TableDataProps {
  title?: string;
  subtitle?: string;
  id?: string;
  key: string;
}

interface Props {
  tableHeaders: TableHeaderProps[];
  tableData?: TableDataProps[][];
  dateFormat?: string;
  onRowClick?: (item: TableDataProps[]) => void;
  defaultSelectedIndex?: number;
  testId?: string;
}

const TableContainer = styled('table')`
  height: 100%;
  display: block;
  border-collapse: separate;
  width: 100%;
`;

const TableHead = styled('thead')`
  display: block;
`;

const TableHeaderRow = styled('tr')`
  display: block;
  max-width: 100%;
`;

const TableHeader = styled('th')`
  width: 100%;
  white-space: nowrap;
  align-items: center;
  padding: 14px 14px 12px;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.grey[100]};
  &:last-of-type {
    text-align: right;
  }
`;

const TableBody = styled('tbody')`
  display: block;
  width: 100%;
`;

const TableRow = styled('tr')<StyledRowProps>`
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.active ? props.theme.palette.action.selected : props.theme.palette.action.hover};
  }
  background-color: ${(props) => (props.active ? props.theme.palette.action.selected : '')};
`;

const TableData = styled('td')<StyledRowProps>`
  width: 100%;
  padding: 8px 16px;
  font-size: ${getRemSize(16)};
  vertical-align: middle;
`;

const SubTableData = styled('div')`
  padding-top: 4px;
  font-size: ${getRemSize(13)};
  color: ${(props) => props.theme.palette.grey[600]};
`;

const NoDataContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const NO_DATA = 'No results found';

const Table = (props: Props): JSX.Element => {
  const { tableHeaders, tableData, dateFormat = 'DD-MM-YYYY', onRowClick, defaultSelectedIndex, testId } = props;
  const [sortState, setSortState] = useState({ key: 'observationDate', order: SORT_TYPES.DESC, type: DATATYPE.DATE });
  const [activeItem, setActiveItem] = useState<any>({ id: null });

  const sortedData =
    tableData &&
    [...tableData].sort((a: any, b: any): number => {
      if (sortState.type === DATATYPE.DATE) {
        const aDate = a?.[1]?.title;
        const bDate = b?.[1]?.title;
        if (!aDate || !bDate) {
          return 0;
        }
        const aDateFormatted = dayjs(aDate);
        const bDateFormatted = dayjs(bDate);
        if (aDateFormatted.isAfter(bDateFormatted)) {
          return sortState.order === SORT_TYPES.DESC ? -1 : 1;
        } else if (aDateFormatted.isBefore(bDateFormatted)) {
          return sortState.order === SORT_TYPES.DESC ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });

  const onSortToggle = (header: TableHeaderProps): void => {
    const sortOrder = sortState.order === SORT_TYPES.DESC ? SORT_TYPES.ASC : SORT_TYPES.DESC;
    setSortState({ key: header.key, order: sortOrder, type: header.type });
  };

  useEffect(() => {
    const sortedDataExists = sortedData && sortedData.length;
    if (
      sortedDataExists &&
      defaultSelectedIndex !== undefined &&
      defaultSelectedIndex >= 0 &&
      sortedData !== undefined &&
      sortedData[defaultSelectedIndex][0]
    ) {
      setActiveItem(sortedData && sortedData[defaultSelectedIndex][0]);

      if (onRowClick && sortedData && sortedData[defaultSelectedIndex]) {
        onRowClick(sortedData[defaultSelectedIndex]);
      }
    }
  }, []);

  if (tableData && tableData.length && sortedData) {
    return (
      <TableContainer data-test-id={testId}>
        <TableHead>
          <TableHeaderRow>
            {tableHeaders.map((header) => (
              <TableHeader key={header.key}>
                <Stack direction="row" alignItems="center">
                  {header.hasOwnProperty('sort') && (
                    <Stack
                      onClick={() => {
                        onSortToggle(header);
                      }}
                      data-testid={`${header.title}-sort`}
                      sx={{ cursor: 'pointer' }}>
                      <KeyboardArrowUp
                        color={
                          sortState['key'] === header.key && sortState.order === SORT_TYPES.ASC
                            ? 'primary'
                            : 'secondary'
                        }
                        sx={{ marginBottom: '-10px' }}
                      />
                      <KeyboardArrowDown
                        color={
                          sortState['key'] === header.key && !(sortState.order === SORT_TYPES.ASC)
                            ? 'primary'
                            : 'secondary'
                        }
                      />
                    </Stack>
                  )}
                  <Typography variant="subtitle1">{header.title}</Typography>
                </Stack>
              </TableHeader>
            ))}
          </TableHeaderRow>
        </TableHead>
        <TableBody data-test-id={`${testId}-body`}>
          {sortedData.map((item, index) => {
            const styledProps = {
              active: item[0].id === activeItem.id,
            };
            return (
              <TableRow
                data-test-id={`${testId}-row-${index}`}
                key={`${item[0].title}-${index}`}
                {...styledProps}
                onClick={() => {
                  setActiveItem(item[0]);
                  if (onRowClick) {
                    onRowClick(item);
                  }
                }}>
                {item.map((data, x) => {
                  return (
                    <TableData key={x} {...styledProps}>
                      {data?.key === 'observationDate' ? dayjs(data.title).utc(false).format('L') : data.title}
                      {data.subtitle && <SubTableData>{data.subtitle}</SubTableData>}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    );
  }
  return (
    <NoDataContainer>
      <FindInPageOutlinedIcon fontSize="large" />
      <Typography paddingTop={2} variant="body1">
        {NO_DATA}
      </Typography>
    </NoDataContainer>
  );
};

export default Table;
