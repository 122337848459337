import { useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DashboardContext } from '../../../DashboardContext';
import { findParentById, shortenMonth, formatDate } from '../../../Utils/utils';
import { Stack } from '@mui/material';
import { theme } from 'theme';

const OverviewChart = (props: {
  aggregatedData: any[];
  targetData: any[];
  yAxisSuffix: string;
  dateAggregation: string;
}) => {
  const { aggregatedData, targetData, yAxisSuffix, dateAggregation } = props;
  const { overviewChartsLoaded, isReloaded, setOverviewChartsLoaded } = useContext(DashboardContext);

  return (
    <Stack height={1} className="overview-card-chart">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%' } }}
        options={{
          chart: {
            type: 'line',
            backgroundColor: theme.palette.background.paper,
            spacing: [15, 0, 0, 5],
            events: {
              // hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
              load: function (this: Highcharts.Chart) {
                setOverviewChartsLoaded(true);
                const currentElement = findParentById(this.container, 'overview-chart-container');
                const parentSize = currentElement?.getBoundingClientRect().height;

                // for some reason chart heights are different when the charts are first loaded, and also when the page is reloaded
                const offset = overviewChartsLoaded || !isReloaded ? 115 : 135;

                if (parentSize) {
                  this.setSize(undefined, Number((parentSize - offset).toFixed(0)));
                  sessionStorage.removeItem('reloaded');
                }
              },
            },
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: '',
          },
          legend: {
            enabled: targetData.length > 0 ? true : false,
            align: 'right',
            floating: true,
            y: -25,
            verticalAlign: 'top',
          },
          credits: {
            enabled: false,
          },
          xAxis: {
            categories: aggregatedData.map((point) =>
              dateAggregation === 'months' ? shortenMonth(point[0].toString()) : formatDate(point[0]),
            ),
            tickmarkPlacement: 'on',
          },
          yAxis: {
            title: {
              text: null,
            },
            labels: {
              enabled: true,
              formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
                return `${Math.round(Number(this.value))}${yAxisSuffix}`;
              },
            },
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
              states: {
                hover: {
                  enabled: false,
                },
              },
              enableMouseTracking: false,
            },
          },
          series: [
            {
              data: aggregatedData.map((point) => point[1]),
              color: '#AAA1FD',
              name: 'Actual',
              showInLegend: false,
            },
            {
              data: targetData,
              color: '#FF969D',
              name: 'Target',
            },
          ],
        }}
      />
    </Stack>
  );
};

export default OverviewChart;
