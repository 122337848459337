import { CareplanFilter, CareplanStatusFilter, RoDashboardTabs } from 'op-enums';
import { BaseConfigInterface } from './types';
import { BOOLEAN_TBC_OPTIONS, BOOLEAN_UNKNOWN_OPTIONS } from '../TreatmentInfoPage/Interface';

const us_config: BaseConfigInterface = {
  SpecialPhysicsConsultVisible: true,
  RequestTemplateChangeActive: true,
  ErrorPopup: {
    Generic:
      "We encountered an error. Please refresh the page. If the issue persists, please contact support: <a href='mailto:support-us@lumonus.com'>support-us@lumonus.com</a>",
    Practitioner:
      "We encountered an error. Please refresh the page. If the issue persists, please contact support: <a href='mailto:support-us@lumonus.com'>support-us@lumonus.com</a>",
    Patient: 'We encountered an error. Please refresh the page. If the issue persists, please contact your clinic.',
  },
  ReferringPhysicians: {
    oncologist: 'Responsible Physician',
    generalPractitioner: 'Primary Physician (GP)',
    referringSurgeon: 'Referring Physician 1',
    referringSurgeon2: 'Referring Physician 2',
    referringSurgeon3: 'Referring Physician 3',
  },
  DefaultValue: {
    cied: 'tbc',
    patientStatus: 'unknown',
    previousRadioTreatment: 'Unknown',
    treatmentRORequired: 'true',
    tumourName: 'tumor',
  },
  Clinex: {
    betaTesting: true,
  },
  Intake: {
    disabled: false,
    isProstateDisabled: false,
    breastMandatoryFields: [],
    lungMandatoryFields: [],
    headNeckMandatoryFields: [],
    lymphomaMandatoryFields: [],
    breastFormVersion: 2,
  },
  PatientSummary: {
    isVisible: true,
    labsEnabled: false,
    RODocumentUpload: false,
    documentFilterField: 'type',
    hideInsuranceTab: false,
  },
  OutcomesPage: {
    isVisible: false,
    DateFormat: 'MMM-DD-YYYY',
    DateTimeFormat: 'MMM DD YYYY h:mma',
  },
  OTRPage: {
    isVisible: true,
    DateFormat: 'MMM-DD-YYYY',
    DateTimeFormat: 'MMM DD YYYY h:mma',
  },
  DisplayButton: {
    displaySimButton: true,
  },
  DisplayModal: {
    submission: false,
  },
  TreatmentInfoPage: {
    mandatoryFields: ['intent', 'treatingDepartment', 'cied', 'previousRadioTreatment'],
    ReadyForCareDate: false,
    AdditionalBookingInfo: false,
    DelayCategory: false,
    MDT: false,
    PreviousTreatmentRetreatment: false,
    PreviousTreatmentRetreatmentType: false,
    AuthorizedICDTreatingDepartments: [],
    Hormones: false,
    Brachytherapy: false,
    ccip: true,
    TreatmentStartDate: true,
    ConsentStatus: false,
    PharmacyPrescription: false,
  },
  SimulationPage: {
    SpacerGelWarning: false,
    imagingText: 'Add outside imaging request',
    imagingCountTitle: 'Outside imaging',
    disableExtentDefined: false,
    disablePositionSetup: false,
    immobilisationLabel: 'Immobilization',
  },
  PrescriptionPage: {
    MicrodosimetryTld: true,
    WeeklyPhysicsCheck: true,
    BasicDoseCalculation: true,
  },
  SubmissionPage: {
    PlanAimSubmissionType: 'Prescription Directive',
    CPOTEnabled: false,
    PlanAimHidden: false,
  },
  VolumingPage: {
    UsePrioritizedOARs: true,
    TargetValueDoseSuffixEnabled: true,
  },
  RadiationDashboard: {
    dateTimeFormat: 'MM/DD/YYYY hh:mm A',
    active: RoDashboardTabs.APPOINTMENTS,
    careplanDashboard: {
      careplanStatusFilters: [
        { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
        { id: CareplanStatusFilter.SIMULATION, name: CareplanStatusFilter.SIMULATION, checked: true },
        {
          id: CareplanStatusFilter.PRESCRIPTION_DIRECTIVE,
          name: CareplanStatusFilter.PRESCRIPTION_DIRECTIVE,
          checked: true,
        },
        { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
      ],
      careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
      isPatientSummaryLinkEnabled: true,
    },
    showFilters: true,
  },
  QuestionLabel: {
    CIED: 'Cardiac Implantable Electronic Device (CIED)',
    RoRequired: 'Primary Physician required',
    GoldSeeds: 'Fiducials',
    phase: 'Sequence',
  },
  QuestionOptions: {
    CIED: BOOLEAN_TBC_OPTIONS,
    previousRadioTreatment: BOOLEAN_UNKNOWN_OPTIONS,
  },
  QuestionValidationChildren: {
    CIED: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'],
    Staging: {
      Primary: {
        RO: ['laterality'],
        MO: ['laterality'],
      },
      Metastasis: {
        RO: ['laterality'],
        MO: [],
      },
    },
  },
  ResetMutationVariableDefaults: {
    CIED: {
      ciedProtocolRequired: '',
      preTreatmentCiedCheckRequired: '',
      referralToGcCardiologist: '',
      otherCiedInstructions: '',
    },
    CiedProtocolRequired: {
      preTreatmentCiedCheckRequired: '',
      referralToGcCardiologist: '',
      otherCiedInstructions: '',
    },
  },
  GetMutationVariableDefaults: {
    CIED: {
      ciedProtocolRequired: 'yes',
      preTreatmentCiedCheckRequired: 'yes',
      referralToGcCardiologist: 'no',
      otherCiedInstructions: '',
    },
    CiedProtocolRequired: {
      preTreatmentCiedCheckRequired: 'yes',
      referralToGcCardiologist: 'no',
      otherCiedInstructions: '',
    },
  },
  DoseSiteSummary: {
    enabled: true,
    DateFormat: 'MM/DD/YYYY',
  },
  SubmitDiagnosisOnlyAndExit: false,
  CardioReferralField: false,
  Tooltips: {
    hasPriority: true,
    hasClinicalPathologicalStaging: true,
  },
  UseDocumentTimezone: false,
  DefaultTimezone: 'US/Eastern',
  DateFormat: 'MM DD YYYY',
  CareplanVoidTimeRestriction: 48,
  CareplanVoidDescription: 'Documentation will be voided in your OIS.',
  EmailMaxLength: 40,
  Outage: {
    showBanner: false,
    link: '',
    message: '',
  },
  Notes: {
    EnableSubmit: false,
    DateTimeFormat: 'MM/DD/YYYY hh:mm A',
  },
  DefaultLocale: 'en',
  SupportEmail: 'support-us@lumonus.com',
  ShowResetDemoDataMenuLink: false,
  Insights: {
    enabled: true,
    mockData: false,
    defaultDateFilter: 'Past 90 days',
    doseUnit: 'cGy',
    dataSource: 'ARIA',
    chartDateFormat: 'MM/DD/YYYY',
    linacs: [],
    defaultDepartment: 'NCCC',
    Dashboards: {
      careplanAdherence: false,
      techniques: true,
      averageFractions: true,
      totalReferrals: false,
      totalDiagnosis: true,
      totalPlans: false,
      linacs: true,
      simToTreatment: true,
      averageDailyPlans: false,
      totalConsultations: false,
      totalSimulations: true,
      totalTreatments: true,
    },
  },
  MenuItems: {
    manageProtocols: true,
  },
};

export default us_config;
