import { NurseReviewedIcon } from 'shared-components/images';
import { SECTIONS } from './routes';
import { PersonOutlined as PersonOutlinedIcon } from '@mui/icons-material';
import React from 'react';
import { ReviewInfo } from 'shared-components/components/UIFormComponents/ReviewBanner';

export const CHART_CHECK = 'chartCheck';

export const yesNKAOptions = [
  { label: 'Yes', value: true },
  { label: 'NKA - nil known allergies', value: false },
];

/* Implanted medical device constants */
const DEFIBRILLATOR = 'Defibrillator';
const PACEMAKER = 'Pacemaker';
const PICC = 'PICC';
const PORT = 'Port';
const CVC = 'CVC';
const IVC = 'IVC';
const JOINT_REPLACEMENT = 'Joint replacement';
const HEART_STENT = 'Heart stent';

const ciedOptions = [DEFIBRILLATOR, PACEMAKER];
const venousAccessTypeOptions = [PICC, PORT, CVC, IVC];
const otherImplantedDevicesOptions = [JOINT_REPLACEMENT, HEART_STENT];

/* Infectious diseases constants */
const HIV = 'HIV';
const HEPATITISB = 'Hepatitis B';
const HEPATITISC = 'Hepatitis C';
const CHICKENPOX = 'Chicken pox';
const INFLUENZA = 'Influenza';
const TUBERCULOSIS = 'Tuberculosis';
const OTHER = 'Other';

/* MRO infections constants */
const MRSA = 'MRSA – Golden Staph';
const VRE = 'VRE';
const CPE = 'CPE';
const MRO = 'MRO';

export const infectiousDiseases = [
  { label: HIV, value: HIV },
  { label: HEPATITISB, value: HEPATITISB },
  { label: HEPATITISC, value: HEPATITISC },
  { label: CHICKENPOX, value: CHICKENPOX },
  { label: INFLUENZA, value: INFLUENZA },
  { label: TUBERCULOSIS, value: TUBERCULOSIS },
  { label: OTHER, value: OTHER },
];

const mroInfections = [
  { label: MRSA, value: MRSA },
  { label: VRE, value: VRE },
  { label: CPE, value: CPE },
  { label: MRO, value: MRO },
  { label: OTHER, value: OTHER },
];

/* Allergy/sensitivity constants */
const DRESSING_OR_TAPE = 'Dressing or tape';
const FOOD = 'Food';
const MEDICATION = 'Medication';

/* Reaction constants */
const ANAPHYLAXIS = 'Anaphylaxis';
const BREATHING_DIFFICULTIES = 'Breathing difficulties';
const CONFUSION_HALLUCINATIONS = 'Confusion/ Hallucinations ';
const DIARRHOEA = 'Diarrhoea';
const DROWSINESS = 'Drowsiness';
const NAUSEA_VOMITING = 'Nausea and Vomiting';
const COUGH = 'Cough'; // Deprecated
const HIVES = 'Hives';
const OEDEMA = 'Oedema';
const PAIN = 'Pain';
const PRURITUS_TINGLING_NUMBNESS = 'Pruritus, Tingling, Numbness';
const RASH = 'Rash';
const REDNESS_DISCOLORATION = 'Redness/Discoloration';
const SWELLING = 'Swelling';
const UNABLE_TO_RECALL = 'Unable to recall'; // Deprecated
const UNKNOWN = 'Unknown';

const allergyOptions = [
  { label: DRESSING_OR_TAPE, value: DRESSING_OR_TAPE },
  { label: FOOD, value: FOOD },
  { label: MEDICATION, value: MEDICATION },
  { label: OTHER, value: OTHER },
];

const reactionOptions = [
  { label: ANAPHYLAXIS, value: ANAPHYLAXIS },
  { label: BREATHING_DIFFICULTIES, value: BREATHING_DIFFICULTIES },
  { label: CONFUSION_HALLUCINATIONS, value: CONFUSION_HALLUCINATIONS },
  { label: DIARRHOEA, value: DIARRHOEA },
  { label: DROWSINESS, value: DROWSINESS },
  { label: HIVES, value: HIVES },
  { label: NAUSEA_VOMITING, value: NAUSEA_VOMITING },
  { label: OEDEMA, value: OEDEMA },
  { label: PAIN, value: PAIN },
  { label: PRURITUS_TINGLING_NUMBNESS, value: PRURITUS_TINGLING_NUMBNESS },
  { label: RASH, value: RASH },
  { label: REDNESS_DISCOLORATION, value: REDNESS_DISCOLORATION },
  { label: UNKNOWN, value: UNKNOWN },
  { label: OTHER, value: OTHER },
];

export const noDataTableRow = [[{ title: 'No patient reported information found', value: '' }]];

export const previousRadiationTreatmentTableFields = [
  { title: 'Type of condition', fieldName: 'condition' },
  { title: 'Part of body', fieldName: 'bodyPart' },
  { title: 'Doctor', fieldName: 'doctor' },
  { title: 'Name of clinic/hospital', fieldName: 'hospital' },
  { title: 'Date treatment completed', fieldName: 'treatmentDate' },
];

export const preCtChartCheckContent = {
  basicInformation: {
    fields: {
      nurseLocation: 'QCL Location*', // mandatory field
      patientIdentificationCheck: 'Patient Identification Check',
      patientHeight: 'Patient reported height',
      patientWeight: 'Patient reported weight',
      previousRadiationTreatment: 'Previous Radiation Treatment',
    },
    tableTitles: {
      typeOfCondition: 'Type of condition',
    },
  },
  chemoRisk: {
    fields: {
      anticancerDrugs: 'Concurrent anticancer drugs',
      chemoPastWeek: 'Chemotherapy in the last 7 days',
    },
    infoHelpers: {
      anticancerDrugsQCLMessage: 'Selecting "Yes" will create QCL: Cytotoxic Mx Plan',
      chemoPastWeekQCLMessage: 'Selecting "Yes" will create Alert: Cytotoxic precautions',
    },
    integrationQCL: {
      anticancerDrugs: 'Cytotoxic Mx Plan',
      chemoPastWeek: 'Cytotoxic Mx Plan',
    },
    tableTitles: {
      typeOfCondition: 'Type of condition',
      typeOfTreatment: 'Treatment type',
      dateOfLastTreatment: 'Date of last treatment',
      treatmentStageTitle: 'Treatment stage',
      doctorTitle: 'Doctor',
      nameOfClinic: 'Name of clinic/hospital',
    },
  },
  implantedMedicalDevices: {
    subSection: {
      title: 'Implanted Devices',
      summary: 'Please select/unselect all that apply',
      validation: 'Please select or specify at least one implanted medical device',
    },
    fields: {
      hasImplantedMedicalDevice: 'Implanted Medical Device',
      cied: 'Cardiac Implanted Electronic Device (CIED)',
      venousAccessType: 'Venous Access Type',
      otherImplantedDevices: 'Other Implanted Devices',
      otherNotListed: 'Other (if not listed above)',
    },
    fieldKeys: {
      cied: 'cied',
      venousAccessType: 'venousAccessType',
      otherImplantedDevices: 'otherImplantedDevices',
      otherNotListed: 'otherNotListed',
    },
    infoHelpers: {
      ciedAlert: 'Selecting a device will create Alert: CIED',
      ciedQcl: 'Selecting a device will create QCL: CIED monitoring plan',
      venousAccessType: 'Selecting a device will create Alert: Implanted device',
      otherImplantedDevices: 'Selecting a device will create Alert: Implanted device',
      otherNotListed: 'Specifying a device will create Alert: Implanted device',
    },
    integrationQCL: {
      cied: 'CIED Monitoring Plan',
    },
    options: {
      cied: ciedOptions,
      venousAccessType: venousAccessTypeOptions,
      otherImplantedDevices: otherImplantedDevicesOptions,
    },
    parentField: 'implantedMedicalDevice',
  },
  infectiousRisk: {
    fields: {
      hasInfectiousDisease: 'Current Infectious Disease',
      hasMroInfection: 'Current MRO Infection',
      outsideWaLastTwelveMonths: 'Inpatient outside WA in last 12 months',
      infectiousDisease: 'Infectious Disease',
      mroInfection: 'MRO Infection',
    },
    infoHelpers: {
      infectiousRiskAlert: 'Selecting "Yes" will create Alert: Infection Risk',
      infectiousRiskQCL: 'Selecting "Yes" will create QCL: Infection Risk Mx Plan',
    },
    options: {
      infectiousDiseaseOptions: infectiousDiseases,
      mroInfectionOptions: mroInfections,
    },
    subfields: {
      infectiousDisease: ['baseValue', 'otherValue'],
      mroInfection: ['baseValue', 'otherValue'],
    },
    integrationQCL: {
      hasInfectiousDisease: 'Infection Risk Mx Plan',
      hasMroInfection: 'Infection Risk Mx Plan',
      outsideWaLastTwelveMonths: 'Infection Risk Mx Plan',
    },
    actionLabels: {
      addInfectiousRisk: 'Add another current infectious disease',
      addMroInfection: 'Add another MRO infection',
    },
    tableTitles: {
      mroInfectionTitle: 'MRO infection',
      doctorClearanceTitle: 'Doctor clearance',
    },
  },
  fallsRisk: {
    fields: {
      fallLastTwelveMonths: 'Fall in last 12 months',
      safetyConcernsMobility: 'Safety Concerns with Mobility',
    },
    infoHelpers: {
      fallsRiskQCLMessage: 'Selecting "Yes" will create QCL: Falls Risk Assessment',
    },
    integrationQCL: {
      fallLastTwelveMonths: 'Falls Risk Assessment',
      safetyConcernsMobility: 'Falls Risk Assessment',
    },
    tableTitles: {
      walkingDeviceTitle: 'Walking device',
    },
  },
  pressureInjuryRisk: {
    fields: {
      treatmentOverHalfHour: 'Treatment Length >30mins',
      currentInpatient: 'Current bed bound inpatient',
      requiresMaskCast: 'Requires Mask or Cast',
      existingPressureInjuryWound: 'Existing Pressure Injury/Wound',
    },
    infoHelpers: {
      pressureInjuryAlert: 'Selecting Yes will create Alert: Pressure risk',
      pressureInjuryQCL: 'Selecting Yes will create QCL: Pressure Injury Mx Plan',
    },
    integrationQCL: {
      treatmentOverHalfHour: 'Pressure Injury Mx Plan',
      currentInpatient: 'Pressure Injury Mx Plan',
      requiresMaskCast: 'Pressure Injury Mx Plan',
      existingPressureInjuryWound: 'Pressure Injury Mx Plan',
    },
    tableTitles: {
      activeInjuryReasonTitle: 'Please specify: Existing Pressure Injury/Wound',
    },
  },
  wellbeing: {
    fields: {
      staffConcern: 'Staff Concern',
      anxietyClaustrophobia: 'Anxiety/Claustrophobia',
    },
    infoHelpers: {
      anxietyClaustrophobiaMessage: 'Please document details/actions in patient notes',
    },
    tableTitles: {
      hasWellbeingIssueTitle: 'Do you have any wellbeing or mental issues?',
      dtScoreTitle: 'Distress Thermometer Score',
      wellbeingIssueTitle: 'Wellbeing or mental health issue',
    },
  },
  advancedCareDirective: {
    fields: {
      hasCurrentAcd: 'Current Advance Care Directive',
      acdInfoRequested: 'ACD info requested',
      hasCurrentResusMxPlan: 'Current Resus Mx Plan/NFR',
    },
    infoHelpers: {
      hasCurrentAcdQCL: 'Select "Yes" will create QCL: Follow-up copy ACD plan',
      hasCurrentResusMxPlanQCL: 'Select "Yes" will create QCL: Follow-up copy Resus Mx /NFR plan',
    },
    integrationQCL: {
      hasCurrentAcd: 'Follow-up copy ACD plan',
      hasCurrentResusMxPlan: 'Follow-up copy Resus Mx /NFR plan',
    },
  },
  allergies: {
    fields: {
      hasPatientAllergies: 'Patient Allergies',
      pcccAllergy: 'Allergy/Sensitivity',
      pcccReaction: 'Reaction experienced',
    },
    infoHelpers: {
      allergyAlert: 'Please add NKA to allergy tab on patient chart',
    },
    options: {
      allergy: allergyOptions,
      reaction: reactionOptions,
    },
    actionLabels: {
      addAllergy: 'Add another allergy/sensitivity',
      addReaction: 'Add another reaction experienced',
    },
  },
  reviewAndSubmit: {
    qclGeneration:
      'Submitting this assessment will generate the following QCLs in Mosaiq. Please find and complete these in Mosaiq after submitting this form.',
  },
  additionalNotes: {
    sectionTitle: 'Additional Notes',
    helperText: 'Notes will appear in Mosaiq once this assessment is submitted',
  },
};

export const basicInfoFields = Object.keys(preCtChartCheckContent.basicInformation.fields);
export const chemoRiskFields = Object.keys(preCtChartCheckContent.chemoRisk.fields);
export const implantedDevicesFields = Object.keys(preCtChartCheckContent.implantedMedicalDevices.fields);
export const infectiousRiskFields = Object.keys(preCtChartCheckContent.infectiousRisk.fields);
export const fallsRiskFields = Object.keys(preCtChartCheckContent.fallsRisk.fields);
export const pressureInjuryFields = Object.keys(preCtChartCheckContent.pressureInjuryRisk.fields);
export const wellbeingCCFields = Object.keys(preCtChartCheckContent.wellbeing.fields);
export const advancedCareFields = Object.keys(preCtChartCheckContent.advancedCareDirective.fields);

// Validation

// Rules
export const displayTextFieldOptionsForOther = [OTHER];
export const displayTextFieldOptionsForAllergy = [DRESSING_OR_TAPE, MEDICATION, OTHER];
export const stepperTitle = 'INITIAL CHART CHECK';

export const reviewInfo: ReviewInfo[] = [
  {
    icon: () => React.createElement(PersonOutlinedIcon, { color: 'info' }),
    label: 'Patient reported',
  },
  { icon: NurseReviewedIcon, label: 'Nurse reviewed and edited' },
];

export const valueRequired = 'This field is required';
export const positiveNumber = 'Number must be positive';

export const fieldsToValidateOnContinue = {
  [SECTIONS.BASIC_INFORMATION]: [...Object.keys(preCtChartCheckContent.basicInformation.fields)],
  [SECTIONS.CHEMOTHERAPY_RISK]: [...Object.keys(preCtChartCheckContent.chemoRisk.fields)],
  [SECTIONS.IMPLANTED_MEDICAL_DEVICES]: [...Object.keys(preCtChartCheckContent.implantedMedicalDevices.fields)],
  [SECTIONS.INFECTIOUS_RISK]: ['hasInfectiousDisease', 'hasMroInfection', 'outsideWaLastTwelveMonths'],
  [SECTIONS.FALLS_RISK]: [...Object.keys(preCtChartCheckContent.fallsRisk.fields)],
  [SECTIONS.PRESSURE_INJURY]: [...Object.keys(preCtChartCheckContent.pressureInjuryRisk.fields)],
  [SECTIONS.WELLBEING]: [...Object.keys(preCtChartCheckContent.wellbeing.fields)],
  [SECTIONS.ADVANCED_CARE_DIRECTIVE]: [...Object.keys(preCtChartCheckContent.advancedCareDirective.fields)],
  [SECTIONS.ALLERGIES]: ['hasPatientAllergies'],
  [SECTIONS.REVIEW_AND_SUBMIT]: [],
};
