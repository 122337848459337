import Highcharts from 'highcharts';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const COMPLEX_TECHNIQUES = ['IMRT', 'VMAT', 'IMRT/VMAT', 'Stereotactic', 'Complex'];

const APPOINTMENT_TYPES = [
  'Sim',
  'Treat',
  'treat',

  // TODO: hardcoded for SGCC. Need a better way of dynamically getting these Sim/Treat types
  // https://lumonus.atlassian.net/browse/HOP-1202
  'CT-A',
  'CT-B',
  'CT- C',
  'CT-D',
  'CT-F',
  'CT RESCAN',
  'CTS A',
  'CTS B',
  'CTS C',
  'CTS D',
  'CTS F',
  'CTS RESCAN',
  'DHB CT-A',
  'DHB CT-B',
  'DHB CT-C',
  'DHB CT-D',
  'DHB CT-F',
  'DHB CT-R',
  'DHB ST-A',
  'DHB ST-B',
  'DHB ST-D',
  'DHB TMT-B',
  'DHB TMT-D',
  'DHB TMT-E',
  'MRL SIM',
  'MRLSIM RSC',
  'TMT-A',
  'TMT-B',
  'TMT-C',
  'TMT-D',
  'TMT-E',
  'TMT-E2',
  'TMT-F',
  'TMTS-A',
  'TMTS-B',
  'TMTS-C',
  'TMTS-D',
  'TMTS-E',
  'TMTS-E2',
  'TMTS-F',
  'ST-A',
  'ST-B',
  'STX-A',
  'STX-B',
];

const sortFunctions: any = {
  days: (a: string, b: string) => a.localeCompare(b),
  weeks: (a: string, b: string) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]),
  months: (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
};

const getWeekNumber = (date: Date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  return Math.ceil(((date.getTime() - firstDayOfYear.getTime()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
};

const findParentById = (element: HTMLElement, id: string) => {
  let currentElement = element;
  while (currentElement !== null && currentElement !== document.body) {
    if (currentElement.id === id) {
      return currentElement;
    }
    currentElement = currentElement.parentElement!;
  }
  return null;
};

const parseDate = (dateStr: string): number => {
  const [month, day, year] = dateStr.split('/').map(Number);
  return Date.UTC(year, month - 1, day);
};

const groupSmallSlicesIntoOther = (data: any[]) => {
  const total = data.reduce((sum, item) => sum + item.y, 0);
  const threshold = total * 0.1;

  const groupedData = [];
  let otherTotal = 0;

  data.forEach((item) => {
    if (item.y < threshold) {
      otherTotal += item.y;
    } else {
      groupedData.push(item);
    }
  });

  if (otherTotal > 0) {
    groupedData.push({
      name: 'Other',
      y: otherTotal,
      color: 'rgba(200, 200, 200)',
      colorIndex: 999,
    });
  }

  return groupedData;
};

const groupCategoriesIntoOther = (data: any[][], threshold = 0.1) => {
  // Validate input
  if (data.length < 2) {
    return data;
  }

  // Extract headers and data rows
  const [headers, ...rows] = data;

  // Initialize totals for each category (excluding submittedAt)
  const categoryTotals: { [key: string]: number } = {};
  let grandTotal = 0;

  // Calculate totals for each category
  headers.forEach((header, index) => {
    if (header !== 'submittedAt') {
      categoryTotals[header] = rows.reduce((sum, row) => sum + row[index], 0);
      grandTotal += categoryTotals[header];
    }
  });

  // Calculate percentages and identify categories to group
  const categoriesToGroup: string[] = [];
  const percentages: { [key: string]: number } = {};

  headers.forEach((header) => {
    if (header !== 'submittedAt') {
      const percentage = categoryTotals[header] / grandTotal;
      percentages[header] = percentage;
      if (percentage < threshold) {
        categoriesToGroup.push(header);
      }
    }
  });

  // If no categories need grouping, return original data
  if (categoriesToGroup.length === 0) {
    return data;
  }

  // Create new headers
  const newHeaders = headers.filter((header) => header === 'submittedAt' || !categoriesToGroup.includes(header));
  newHeaders.push('Other');

  // Create new rows
  const newRows = rows.map((row) => {
    const newRow = row.filter(
      (_, index) => headers[index] === 'submittedAt' || !categoriesToGroup.includes(headers[index]),
    );

    // Calculate sum of grouped categories for this row
    const otherSum = categoriesToGroup.reduce((sum, category) => {
      const index = headers.indexOf(category);
      return sum + row[index];
    }, 0);

    newRow.push(otherSum);
    return newRow;
  });

  return [newHeaders, ...newRows];
};

Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
  return [
    // Rectangle (Bottom Bar)
    'M',
    x + w * 0.22,
    y + h * 0.83,
    'L',
    x + w * 0.78,
    y + h * 0.83,
    'L',
    x + w * 0.78,
    y + h * 0.75,
    'L',
    x + w * 0.22,
    y + h * 0.75,
    'Z',

    // Arrow Shape
    'M',
    x + w * 0.78,
    y + h * 0.375,
    'L',
    x + w * 0.62,
    y + h * 0.375,
    'L',
    x + w * 0.62,
    y + h * 0.125,
    'L',
    x + w * 0.38,
    y + h * 0.125,
    'L',
    x + w * 0.38,
    y + h * 0.375,
    'L',
    x + w * 0.22,
    y + h * 0.375,
    'L',
    x + w * 0.5,
    y + h * 0.67,
    'Z',
  ];
};

const convertDateFormat = (dateStr: string): string => {
  if (!dateStr) return '';
  const [year, month, day] = dateStr.split('-');
  return `${month}/${day}/${year}`;
};

const shortenMonth = (dateString: string) => {
  const month = dateString.split(' ')[0];
  const year = dateString.split(' ')[1];
  return month.substring(0, 3) + ' ' + year.substring(2, 4);
};

const getDeterministicOffset = (value: number, min: number, max: number) => {
  const hash = value
    .toString()
    .split('')
    .reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

  return (Math.abs(hash) % (max - min + 1)) + min;
};

const countWeekdays = (startDate: Date, endDate: Date): number => {
  let count = 0;
  const currentDate = new Date(startDate);
  while (currentDate < endDate) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // Skip Sundays (0) and Saturdays (6)
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return count;
};

const ABBREVIATED_TYPES = ['gi'];

const isSimAppointment = (appointment: any): boolean => {
  const appType = appointment.appointmentType?.toLowerCase();
  const appDescription = appointment.description?.toLowerCase();
  return appType?.includes('sim') || appDescription?.includes('sim');
};

const isTreatmentAppointment = (appointment: any): boolean => {
  const appType = appointment.appointmentType?.toLowerCase();
  const appDescription = appointment.description?.toLowerCase();
  return appType?.includes('treat') || appDescription?.includes('treat');
};

const isLinacLocation = (location: string): boolean => {
  const loc = location.toLowerCase();
  const validLinacs = CurrentAppConfig.Insights?.linacs;

  // if no linacs are specified in the app config, assume all locations are linacs and return true
  if (!validLinacs || validLinacs.length === 0) return true;

  return validLinacs.some((linac) => loc === linac.toLowerCase());
};

const formatDate = (date: any): string => {
  if (CurrentAppConfig.Insights?.chartDateFormat === 'MM/DD/YYYY') {
    return new Date(date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  } else {
    return new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
  }
};

export {
  COMPLEX_TECHNIQUES,
  sortFunctions,
  getWeekNumber,
  findParentById,
  parseDate,
  groupSmallSlicesIntoOther,
  groupCategoriesIntoOther,
  convertDateFormat,
  shortenMonth,
  getDeterministicOffset,
  ABBREVIATED_TYPES,
  countWeekdays,
  isSimAppointment,
  isTreatmentAppointment,
  isLinacLocation,
  formatDate,
  APPOINTMENT_TYPES,
};
